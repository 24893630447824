.main_sidebar {
  // position: absolute;
  // z-index: 401;
  // left: 70px;

  display: flex;
  justify-content: space-between;

  width: 0%;
  padding: 0;
  overflow-y: scroll !important;
  overflow-x: hidden;
  height: 100vh;
  //width: 30%;

  background-color: rgb(255, 255, 255);
  box-shadow: 8px 0px 20px 2px #cccccc87;

  //transition: 0.5s ease-in-out;

  // input,
  // select {
  //   //border-radius: 8px;
  //   height: 30px;
  //   width: 100%;
  //   margin-bottom: 10px;

  //   outline: none;
  //   border: none;

  //   color: rgb(0, 0, 0);
  // }

  .resizeButtons {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 5%;
    margin: 4px;

    button {
      font-weight: 900;
      font-size: 20px;
      background: none;
      border: none;

      color: $vektaBlueDark;

      &:hover {
        background-color: rgb(219 219 219 / 22%);
        border-radius: 8px;
      }
    }
  }

  .widgets {
    //display: none;
    height: 100%;
    width: 93%;
    //padding: 10px
    padding: 16px 0 16px 16px;

    flex-direction: column;

    .widgetTour {
      margin-left: 10px;
      border: #adadad 1.5px solid;
      width: 30px;
      height: 30px;
    }

    &.main_sidebarLoader_wrapper {
      height: fit-content;
      min-height: 100%;
      position: relative;

      padding: 16px 0 16px 16px;

      .main_sidebarLoader_overlay {
        background: rgba(180, 180, 180, 0.527);
      }

      .main_sidebarLoader_content {
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        // justify-content: center;

        position: fixed;
        top: 50%;
        left: 25rem;
        transform: translate(0, -50%);

        // position: fixed;
        // top: 50%;
        // width: 500px;
        // margin: 5% auto;
        // /* Only centers horizontally not vertically! */
        // left: 0;
        // right: 0;
      }
    }

    hr {
      //margin: 0 !important;
      margin: 8px 0 0 0;
      border-top: 1.5px solid grey;
      width: 100%;
    }

    .input {
      margin-bottom: 5px;

      input,
      select {
        height: 45px !important;
        border-radius: 8px;
        width: 100%;
        border: none;
      }

      .toggle_missingInfoShake {
        animation: shakeError 0.2s ease-in-out 0s 2;
      }

      @keyframes shakeError {
        0% {
          margin-left: 0rem;
        }

        25% {
          margin-left: 0.5rem;
        }

        75% {
          margin-left: -0.5rem;
        }

        100% {
          margin-left: 0rem;
        }
      }

      .toggle_missingInfo {
        border: 2px solid red;
      }
    }

    .inputSection {
      background-color: #d3d3d354;
      border-radius: 8px;

      padding: 16px;
      margin: 16px 0;

      .inputSection_heading {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 5px;
        margin-bottom: 10px;
        border-radius: 8px;

        color: white;
        background: $vektaBlue;

        h4 {
          margin: 15px;
        }

        svg {
          width: 25px;
          margin-right: 10px;
          fill: white;
        }

        .inputControlsHide {
          //background-color: $background;
          background: none;
          border: none;
          outline: none;

          height: 4em;
          width: 4em;
          display: block;
          padding: 0.5em;
          //margin: 1em auto;
          position: relative;
          cursor: pointer;
          border-radius: 4px;
          margin-right: 15px;

          .left-bar {
            position: absolute;
            background-color: transparent;
            top: 18px;
            left: 0;
            width: 20px;
            height: 4px;
            display: block;
            transform: rotate(35deg);
            float: right;
            border-radius: 2px;

            &:after {
              content: "";
              background-color: white;
              width: 20px;
              height: 4px;
              display: block;
              float: right;
              border-radius: 6px 10px 10px 6px;
              transition: all 1s cubic-bezier(0.25, 1.7, 0.35, 0.8);
              z-index: -1;
            }
          }

          .right-bar {
            position: absolute;
            background-color: transparent;
            top: 18px;
            left: 14px;
            width: 20px;
            height: 4px;
            display: block;
            transform: rotate(-35deg);
            float: right;
            border-radius: 2px;

            &:after {
              content: "";
              background-color: white;
              width: 20px;
              height: 4px;
              display: block;
              float: right;
              border-radius: 10px 6px 6px 10px;
              transition: all 1s cubic-bezier(0.25, 1.7, 0.35, 0.8);
              z-index: -1;
            }
          }
        }
      }
    }
  }

  .toggle_content {
    display: flex;

    .project_heading {
      display: none;
    }
  }

  .layersControls {
    overflow-y: hidden;

    .project_layers {
      height: 100%;

      .open {
        .left-bar:after {
          transform-origin: center center;
          transform: rotate(-70deg);
        }

        .right-bar:after {
          transform-origin: center center;
          transform: rotate(70deg);
        }
      }

      .tree {
        height: 55%;
        max-height: 60%;
        overflow-x: scroll;
      }

      .insightSearch {
        .insightSearch_parameters {
          #insightsResultsBox {
            max-height: 350px;
            overflow-y: scroll;
            overflow-x: hidden;
          }
        }
      }

      .project_heading {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .layer_controlBtns {
          display: flex;
          align-items: center;

          .vl {
            border-left: 3px solid $vektaBlueDark;
            //height: calc(30px / var(--scaleFactor));
            height: 30px;

            margin: 0 3px;
          }

          button {
            //background: none;
            border: none;
            border-radius: 8px;

            //padding: calc(5px / var(--scaleFactor));
            padding: 5px;

            margin: 0;

            svg {
              //width: calc(18px / var(--scaleFactor));
              //height: calc(18px / var(--scaleFactor));
              width: 18px;
              height: 18px;
            }

            &:hover {
              background-color: $vektaBlue;

              svg {
                color: white;
                fill: white;
              }
            }

            i {
              font-size: large;
            }

            &.Mui-selected {
              background-color: $vektaBlue;

              color: white;
              fill: white;
            }
          }

          .showHideController {
            display: inline-block;
            position: relative;

            button svg {
              width: 21px;

              //width: calc(21px / var(--scaleFactor));
            }

            .showHideSubOptions {
              position: absolute;
              right: 0;
              z-index: 100;

              width: 120px;
              overflow: auto;

              background-color: #ffffff;
              border: 2px solid $vektaBlue;

              padding: 5px;
              border-radius: 8px;

              a {
                display: block;
              }
            }

            .openSubMenu {
              display: block;
            }
          }
        }
      }

      .projectTree {
        height: 85%;
        border-radius: 8px;

        padding: 5px;
        margin-top: 16px;

        overflow-x: hidden;
        overflow-y: scroll;

        transition: 1.5s cubic-bezier(0, -1.37, 0, 1.2);

        .globalLayers,
        .ukLayers {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .globalLayers_search,
          .ukLayers_search {
            width: 45%;
            position: relative;

            span {
              position: absolute;
              right: 15px;
              top: 6px;
            }

            input {
              border-radius: 8px;
              outline: none;
              border: 2px solid #cccccc;

              height: 3.5rem;
              width: 100%;
            }
          }
        }

        .projectJSTree {
          height: 100%;

          .jstree-clicked {
            background: $vektaBlueDark !important;
          }

          .jstree-hovered {
            background: $vektaBlue !important;
          }

          .jstree-search {
            color: $vektaBlue !important;
          }

          .jstree-rename-input {
            color: black;
          }

          .jstree-default a {
            white-space: normal !important;
            height: auto;
          }

          .jstree-anchor {
            height: auto !important;
          }

          .jstree-default li > ins {
            vertical-align: top;
          }

          .jstree-leaf {
            height: auto;
          }

          .jstree-leaf a {
            height: auto !important;
            white-space: initial;
          }
        }

        .sizingContainer {
          // .rhandsontable {
          //   height: calc(450px / var(--scaleFactor)) !important;

          //   .wtHolder {
          //     height: calc(450px / var(--scaleFactor)) !important;
          //   }
          // }
          .rhandsontable {
            height: 450px !important;

            .wtHolder {
              height: 450px !important;
            }
          }
        }
      }

      .extend_section {
        height: 83%;
      }
    }
  }

  .customLayers {
    height: 95%;

    hr {
      margin-top: 0;
    }

    .customOptions {
      display: flex;
      justify-content: space-between;

      button {
        width: 49%;
        height: 60px;

        background: none;
        border: none;

        border-radius: 8px 8px 0 0;
        background-color: #d3d3d3;

        &.active {
          background-color: #d3d3d354;

          text-decoration: underline;
          text-decoration-color: #9feed6;
          text-decoration-thickness: 3px;
        }
      }
    }

    .draw_layer_settings,
    .upload_layer_settings {
      display: none;
      padding: 6px 12px;

      background-color: #d3d3d354;
      border-radius: 0 0 8px 8px;
    }

    .draw_layer_settings {
      h3 {
        margin-bottom: 10px;
        font-size: 20px;
      }

      .drawBtns {
        display: flex;

        height: 50px;

        button {
          margin: 0;
        }
      }

      .point {
        border-radius: 8px;
        padding: 10px 0;
        margin: 10px 0;

        .range {
          position: relative;
          padding: 15px;

          input {
            -webkit-appearance: none;
            width: 295px;
            height: 4px;
            border-radius: 5px;
            background-color: $vektaBlueDark;
            outline: none;

            &::-webkit-slider-thumb {
              -webkit-appearance: none;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background-color: $vektaBlueDark;
            }
          }

          span {
            position: absolute;
            top: 0;
            right: 0;
            margin-left: 14px;

            width: 50px;
            height: 30px;

            background-color: rgb(212, 212, 212);

            border-radius: 3px;
            text-align: center;
            line-height: 30px;
          }
        }
      }
    }

    .upload_layer_settings {
      h3 {
        margin-bottom: 10px;
        font-size: 20px;
      }

      .multi_layer_upload {
        display: none;
      }

      .uploadSelectionBtns {
        display: flex;
        justify-content: space-around;

        button {
          width: 40%;
        }
      }

      .shiny-input-container {
        width: 100%;
      }
    }

    .custom_layers_edit {
      .layer_edit_btns {
        display: flex;
        justify-content: space-evenly;

        button {
          color: white;
          background-color: $vektaBlue;
          border: none;
          border-radius: 8px;
          padding: 15px;

          &:hover {
            background-color: $vektaBlueDark;
          }
        }
      }

      h3 {
        margin-bottom: 10px;
        font-size: 20px;
      }
    }

    .toggle_custom {
      display: block;
    }
  }

  .weatherAnalysis {
    .windheightinput {
      padding-top: 10px;
    }

    .open {
      .left-bar:after {
        transform-origin: center center;
        transform: rotate(-70deg);
      }

      .right-bar:after {
        transform-origin: center center;
        transform: rotate(70deg);
      }
    }

    .weatherInputs {
      .selectDataPoint {
        display: none;
        flex-direction: column;

        margin: 35px;

        .promt {
          display: flex;
          justify-content: space-between;
        }

        svg {
          width: 25px;
        }
      }

      .uploadWeather {
        display: none;
        margin: 20px 0;

        .shinyUpload {
          .form-group {
            margin-bottom: 0;

            .progress {
              margin-bottom: 0;
            }
          }

          label {
            width: 35%;
          }

          input {
            height: 34px;
          }
        }

        .shiny-html-output {
          color: red;
        }
      }

      .toggle_weather {
        display: flex;
      }
    }

    .weatherOutput {
      .shiny-text-output {
        font-weight: 500;
        line-height: 1.1;
        font-size: 18px;
        text-transform: uppercase;

        color: white;
        background-color: #009ec6;

        margin: 10px auto;
        width: max-content;
        padding: 15px;
        border-radius: 8px;
      }

      img {
        width: 100% !important;
      }
    }

    .weatherDownload {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-top: 16px;

      // button,
      // a {
      //   display: flex;
      //   justify-content: space-around;
      //   align-items: center;

      //   height: 50px;
      //   width: 100%;
      //   margin: 16px 8px;

      //   background-color: $vektaBlueDark;
      //   color: white;

      //   border: none;
      //   border-radius: 8px;

      //   svg {
      //     fill: white;
      //   }

      //   &:hover svg{
      //     fill: $green;
      //   }
      // }

      .disabledButton {
        background-color: grey;
        cursor: not-allowed;

        &:hover svg {
          fill: white;
        }
      }
    }

    .yieldDownload {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-top: 16px;

      // button,
      // a {
      //   display: flex;
      //   justify-content: space-around;
      //   align-items: center;

      //   height: 50px;
      //   width: 100%;
      //   margin: 16px 8px;

      //   background-color: $vektaBlueDark;
      //   color: white;

      //   border: none;
      //   border-radius: 8px;

      //   svg {
      //     fill: white;
      //   }

      //   &:hover svg{
      //     fill: $green;
      //   }
      // }

      .disabledButton {
        background-color: grey;
        cursor: not-allowed;

        &:hover svg {
          fill: white;
        }
      }
    }
    .persistenceInput {
      .multiInput {
        margin-top: 10px;

        .input {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .labelInput {
            width: 100%;
          }

          .unitInput {
            display: flex;

            width: 70%;
          }

          button {
            border: 2px solid $vektaBlueDark;
            border-radius: 8px;
            width: 23%;

            &:hover {
              background-color: $vektaBlueTrans;
            }
          }
        }

        .persistenceOption {
          width: 100%;
        }

        .multiLabel {
          margin: 0;
          color: #8d8d8d;
        }
      }
    }

    .per_buttons {
      display: flex;
      align-items: center;
      justify-content: space-around;

      button {
        width: 40%;
        padding: 6px 12px;
        margin: 10px 0 20px 0;

        border: none;
        border-radius: 4px;

        background-color: #009ec6;
        color: white;
      }

      .disabledButton {
        background-color: grey;
        cursor: not-allowed;
      }
    }

    .hide_block {
      display: none;
    }
  }

  .elevationAnalysis {
    overflow-y: scroll;
    overflow-x: hidden;

    .gis_includeSlope label {
      text-align: right;
    }

    .waterDepthDraw {
      .promt {
        display: flex;
        justify-content: space-between;
        margin: 35px 3px 0 35px;

        svg {
          width: 24px;
        }
      }

      .elevationLimitation {
        display: flex;
        justify-content: space-around;
      }

      .elevationAnalysis_point {
        background-color: #d3d3d354;
        border-radius: 8px;

        padding: 16px;

        .pointData {
          display: flex;
          justify-content: space-around;

          background-color: $vektaBlueDark;
          padding: 16px;
          border-radius: 8px;
          color: white;
        }
      }

      .inputSection_body {
        margin-top: 16px;
      }

      .additionalDrawBtns {
        display: flex;
        justify-content: space-between;

        button {
          width: 70px;
          height: 40px;

          background-color: #009ec6;
          color: white;

          border-radius: 8px;
          border: none;
        }

        .input {
          input {
            margin: 0;
          }

          label {
            margin: 0;
          }
        }
      }
    }

    .downloadWaterDepth {
      a {
        width: 100%;
        margin: 10px 0 20px 0;

        background-color: $vektaBlue;
        color: white;
      }
    }

    .waterDepthBoundingBox {
      padding: 6px 12px;

      background-color: #d3d3d354;
      border-radius: 0 0 8px 8px;

      margin-top: 10px;
    }

    .waterDepthPoint {
      display: none;

      .shiny-text-output {
        font-weight: 500;
        line-height: 1.1;
        font-size: 18px;
        text-transform: uppercase;

        color: white;
        background-color: #009ec6;

        margin: 10px auto;
        width: max-content;
        padding: 15px;
        border-radius: 8px;
      }

      img {
        width: 100% !important;
      }
    }

    .toggle_pointDepth {
      display: block;
    }

    .waterDepthGraph {
      #scene {
        width: 100% !important;
        left: 0 !important;
      }

      .area_waterSlope {
        display: none;
      }

      .area_waterDepthBound {
        display: none;
      }

      .toggle_areaWater {
        display: block;
      }
    }
  }

  .widgetOverlay {
    display: none;
    background: #999;
    opacity: 0.8;

    position: absolute;
    z-index: 1001;
    top: 0;
    left: 0;

    height: 100%;
    width: 30vw;
  }

  .dataSearch {
    overflow-y: scroll;
    overflow-x: hidden;

    .portSearch,
    .aisSearch {
      .inputSection_heading {
        span {
          margin: 0 5px;
        }
      }

      .open {
        .left-bar:after {
          transform-origin: center center;
          transform: rotate(-70deg);
        }

        .right-bar:after {
          transform-origin: center center;
          transform: rotate(70deg);
        }
      }

      .hide_block {
        display: none;
      }

    

      .portSearch_parameters,
      .aisSearch_parameters {
        .groupedInputs {
          display: flex;
          justify-content: space-between;

          .input {
            width: 30%;
          }
        }

        .portLifts_inputs,
        .portCrane_inputs {
          display: flex;
          justify-content: space-between;

          .input {
            width: 30%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
        }

        .portShortList {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .input {
            width: 100%;
          }
        }

        .portSearchTable {
          display: none;

          height: 31%;
          overflow: scroll;
        }

        .toggle_table {
          display: block;
        }
      }
    }
  }
}



.lessSize {
  width: min(35% - 50px, 500px);
  // max-width: 34%;
  // min-width: 34%;
  //padding: 16px 0 16px 8px;
}

.middleSize {
  width: min(45% - 50px, 800px);
  // max-width: 34%;
  // min-width: 34%;
  //padding: 16px 0 16px 8px;
}

.moreSize {
  width: min(65% - 50px, 1100px);
  // max-width: 34%;
  // min-width: 34%;
  //padding: 16px 0 16px 8px;
}

.toggle_main {
  //width: 600px;

  // padding: 10px;
}

// .toggle_extendedMain {
//   width: calc(47vw);
//   max-width: calc(47vw);
// }

// .toggle_gridModify {
//   display: block;
// }
