@font-face {
  font-family: vektaGroupL;
  src: url("custom_font/contl-webfont.woff");
}
@font-face {
  font-family: vektaGroupM;
  src: url("custom_font/contm-webfont.woff");
}
@font-face {
  font-family: vektaGroupB;
  src: url("custom_font/contb-webfont.woff");
}
@media only screen and (min-width: 481px) {
  .GIS_Loader .toolLogin_form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  .GIS_Loader .toolLogin_form .firebase_oAuth {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .GIS_Loader .toolLogin_form .firebase_oAuth button img {
    filter: invert(1);
  }
  .GIS_Loader .toolLogin_form .toolLogin_firebase {
    display: flex;
    flex-direction: column;
  }
  .GIS_Loader .toolLogin_form .toolLogin_firebase .p-password i svg {
    width: 16px;
    height: 16px;
  }
  .GIS_Loader .toolLogin_form .toolLogin_firebase .p-password input,
  .GIS_Loader .toolLogin_form .toolLogin_firebase .p-password .p-icon-field {
    width: 100%;
    height: 3rem;
  }
  .GIS_Loader .toolLogin_form .toolLogin_firebase .p-password input span,
  .GIS_Loader .toolLogin_form .toolLogin_firebase .p-password .p-icon-field span {
    top: 5px;
  }
  .GIS_Loader .toolLogin_form .forgottenPassword_overlay {
    background: rgba(128, 128, 128, 0.4901960784);
    height: 100%;
    width: 32%;
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
  }
  .GIS_Loader .toolLogin_form .toolLogin_footer {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
  }
  .GIS_Loader .toolLogin_form .forgottenPassword {
    text-align: right;
    text-decoration: none;
    font-size: 12px;
    cursor: pointer;
    margin-top: 16px;
  }
  .GIS_Loader .toolLogin_form .forgottenPassword:hover {
    text-decoration: underline;
  }
  .GIS_Loader .toolLogin_form .forgottenPassword_popup {
    position: fixed;
    inset: 44% 5.5%;
    background-color: white;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 16px;
    border-radius: 8px;
    z-index: 2;
  }
  .GIS_Loader .toolLogin_form .forgottenPassword_popup .input label,
  .GIS_Loader .toolLogin_form .forgottenPassword_popup .input input {
    color: black;
  }
  .GIS_Loader .toolLogin_form .forgottenPassword_popup .popup_footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .GIS_Loader .toolLogin_form .forgottenPassword_popup .popup_footer button {
    background-color: #007592;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    padding: 8px;
    margin: 8px;
  }
  .GIS_Loader .toolLogin_form .forgottenPassword_popup .popup_footer button:hover {
    background-color: #007592;
    box-shadow: 0px 0px 8px 0px #939393;
  }
  .GIS_Loader .toolLogin_form .termsConditions {
    font-size: 12px;
    margin-top: 16px;
  }
  .GIS_Loader .toolLogin_form .termsConditions a {
    color: white;
  }
  .GIS_Loader .emailSent_notification {
    position: absolute;
    opacity: 0;
    width: 91%;
    padding: 8px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px;
    margin: 8px 0;
    text-align: center;
    background: rgb(0, 196, 84);
    color: white;
  }
  .GIS_Loader .emailSent_notification.toggle_display {
    animation-name: inout;
    animation-duration: 4s;
  }
  @keyframes inout {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .GIS_Loader .toolSignup_inputs .p-inputtext:enabled:focus {
    box-shadow: none;
  }
  .GIS_Loader .toolSignup_inputs .p-float-label input:focus ~ label,
  .GIS_Loader .toolSignup_inputs .p-float-label .p-inputwrapper-filled ~ label,
  .GIS_Loader .toolSignup_inputs .p-float-label .p-inputwrapper-focus ~ label {
    font-size: 16px;
  }
  .GIS_Loader .toolSignup_inputs .p-password i svg {
    width: 16px;
    height: 16px;
  }
  .GIS_Loader .toolSignup_inputs .p-password input {
    width: 100%;
  }
  .GIS_Loader .toolSignup_inputs .toolSignup_error {
    position: relative;
    opacity: 0;
  }
  .GIS_Loader .toolSignup_inputs .toolSignup_error span {
    width: 100%;
    text-align: center;
    background-color: rgb(255, 87, 87);
    color: white;
  }
  .GIS_Loader .toolSignup_inputs .toggle_error {
    animation-name: inout;
    animation-duration: 4s;
  }
  @keyframes inout {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .GIS_Loader .toolSignup_footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 24px;
  }
  .GIS_Loader .toolSignup_footer .termsConditions {
    font-size: 12px;
    margin-top: 16px;
  }
  .GIS_Loader .toolSignup_footer .termsConditions a {
    color: white;
  }
  .GIS_Loader .toolSignup_footer .p-float-label input.p-filled ~ label {
    top: -1rem !important;
  }
  .GIS_Loader .toolSignup_footer .p-inputtext:enabled:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
  .GIS_Loader .toolSignup_footer .p-float-label input:focus ~ label,
  .GIS_Loader .toolSignup_footer .p-float-label .p-inputwrapper-filled ~ label,
  .GIS_Loader .toolSignup_footer .p-float-label .p-inputwrapper-focus ~ label {
    font-size: 16px;
    top: -1.5rem;
  }
  .GIS_Loader .toolSignup_footer .p-password i svg {
    width: 16px;
    height: 16px;
  }
  .GIS_Loader .toolSignup_footer .p-password input {
    width: 100%;
  }
}
.new_popup,
.existing_popup {
  display: none;
  position: absolute;
  top: 40%;
  left: 46%;
  z-index: 1002;
  background: #fff;
  padding: calc(70px / var(--scaleFactor));
  width: calc(660px / var(--scaleFactor));
  height: calc(700px / var(--scaleFactor));
  overflow: scroll;
  margin-left: calc(-250px / var(--scaleFactor)); /*Half the value of width to center div*/
  margin-top: calc(-250px / var(--scaleFactor)); /*Half the value of height to center div*/
  transition: 0.5s ease-in-out;
  box-shadow: 2px 5px 16px 0px #007592, 5px 5px 15px 5px rgba(0, 0, 0, 0);
}
.new_popup #popup_close,
.existing_popup #popup_close {
  float: right;
  padding: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}
.new_popup #popup_close:hover,
.existing_popup #popup_close:hover {
  background-color: rgb(255, 0, 0);
  color: rgb(255, 255, 255);
}
.new_popup .new_popup_content .popup_heading,
.new_popup .existing_popup_content .popup_heading,
.existing_popup .new_popup_content .popup_heading,
.existing_popup .existing_popup_content .popup_heading {
  display: flex;
  justify-content: space-between;
}
.new_popup .new_popup_content .error,
.new_popup .existing_popup_content .error,
.existing_popup .new_popup_content .error,
.existing_popup .existing_popup_content .error {
  color: rgb(255, 0, 0);
  font-weight: bold;
}
.new_popup .new_popup_content .project_name,
.new_popup .existing_popup_content .project_name,
.existing_popup .new_popup_content .project_name,
.existing_popup .existing_popup_content .project_name {
  margin-top: 10px;
}
.new_popup .new_popup_content .project_name input,
.new_popup .existing_popup_content .project_name input,
.existing_popup .new_popup_content .project_name input,
.existing_popup .existing_popup_content .project_name input {
  margin-top: 10px;
  padding: 5px;
  height: 50px;
  border-radius: 8px;
  border: 2px solid #007592;
}
.new_popup .new_popup_content .setting_options,
.new_popup .existing_popup_content .setting_options,
.existing_popup .new_popup_content .setting_options,
.existing_popup .existing_popup_content .setting_options {
  display: block;
}
.new_popup .new_popup_content .setting_options .setting_option_btns,
.new_popup .existing_popup_content .setting_options .setting_option_btns,
.existing_popup .new_popup_content .setting_options .setting_option_btns,
.existing_popup .existing_popup_content .setting_options .setting_option_btns {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 25px 0px 35px 0px;
}
.new_popup .new_popup_content .setting_options .setting_option_btns button,
.new_popup .existing_popup_content .setting_options .setting_option_btns button,
.existing_popup .new_popup_content .setting_options .setting_option_btns button,
.existing_popup .existing_popup_content .setting_options .setting_option_btns button {
  width: 100px;
  height: 50px;
  cursor: pointer;
  border: none;
  outline: none;
}
.new_popup .new_popup_content .setting_options .setting_option_btns .toggle_btn,
.new_popup .existing_popup_content .setting_options .setting_option_btns .toggle_btn,
.existing_popup .new_popup_content .setting_options .setting_option_btns .toggle_btn,
.existing_popup .existing_popup_content .setting_options .setting_option_btns .toggle_btn {
  background-color: rgb(110, 110, 110);
  color: rgb(255, 255, 255);
}
.new_popup .new_popup_content .action-button,
.new_popup .existing_popup_content .action-button,
.existing_popup .new_popup_content .action-button,
.existing_popup .existing_popup_content .action-button {
  float: right;
  width: 100px;
  height: 30px;
  border: 2px solid #007592;
  background: none;
}
.new_popup .new_popup_content .action-button:hover,
.new_popup .existing_popup_content .action-button:hover,
.existing_popup .new_popup_content .action-button:hover,
.existing_popup .existing_popup_content .action-button:hover {
  background-color: #007592;
  color: rgb(255, 255, 255);
}
.new_popup .new_popup_content .option_info,
.new_popup .existing_popup_content .option_info,
.existing_popup .new_popup_content .option_info,
.existing_popup .existing_popup_content .option_info {
  color: rgb(141, 141, 141);
}
.new_popup .new_popup_content .option_info label,
.new_popup .existing_popup_content .option_info label,
.existing_popup .new_popup_content .option_info label,
.existing_popup .existing_popup_content .option_info label {
  font-weight: bold;
}
.new_popup .new_popup_content .option_info p,
.new_popup .existing_popup_content .option_info p,
.existing_popup .new_popup_content .option_info p,
.existing_popup .existing_popup_content .option_info p {
  margin-top: 5px;
}
.new_popup .existing_popup_content .form-group,
.existing_popup .existing_popup_content .form-group {
  width: 100%;
  margin-top: 15px;
}
.new_popup .existing_popup_content .form-group select,
.existing_popup .existing_popup_content .form-group select {
  height: 250px;
}
.new_popup .existing_popup_content .form-group select option,
.existing_popup .existing_popup_content .form-group select option {
  font-size: 15px;
}

.sidebarButtons {
  width: 60px;
  min-width: 60px;
  height: 100vh;
  background-color: #00A0C6;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sidebarButtons .widgetBtns,
.sidebarButtons .settingsBtns {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sidebarButtons .widgetBtns button,
.sidebarButtons .settingsBtns button {
  margin: 10px 0;
  background: none;
  border: none;
  color: white;
  padding: 5px;
  fill: white;
  height: 4rem;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: unset;
}
.sidebarButtons .widgetBtns button:hover,
.sidebarButtons .settingsBtns button:hover {
  background-color: rgba(0, 158, 198, 0.2549019608);
}
.sidebarButtons .widgetBtns button svg,
.sidebarButtons .widgetBtns button img,
.sidebarButtons .widgetBtns button i,
.sidebarButtons .settingsBtns button svg,
.sidebarButtons .settingsBtns button img,
.sidebarButtons .settingsBtns button i {
  width: 3rem;
  height: 3rem;
  font-size: 30px;
}
.sidebarButtons .widgetBtns button img,
.sidebarButtons .settingsBtns button img {
  filter: invert(1);
}
.sidebarButtons .widgetBtns button .profilePicture img,
.sidebarButtons .settingsBtns button .profilePicture img {
  filter: unset;
  border-radius: 50%;
}
.sidebarButtons .widgetBtns button.active,
.sidebarButtons .settingsBtns button.active {
  border-left: 3px solid white;
}
.sidebarButtons .settingsBtns button {
  margin: 5px 0;
}
.sidebarButtons .settingsBtns .vektaGroup span {
  font-size: 11px;
  font-weight: 500;
  color: white;
  text-align: center;
  display: flex;
}
.sidebarButtons .settingsBtns .vektaGroup a {
  width: 100%;
}
.sidebarButtons .settingsBtns .vektaGroup a button {
  width: 100%;
  margin: 0;
  border-radius: 0;
  height: 35px;
}
.sidebarButtons .settingsBtns .vektaGroup a button img {
  filter: invert(0);
  width: 35px;
  height: 25px;
}

.modal-popups {
  position: fixed;
  z-index: 1001;
  background: #fff;
  padding: 16px;
  border-radius: 8px;
  overflow-y: scroll;
  transition: 0.5s ease-in-out;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  inset: 5rem;
}
.modal-popups .popup_heading {
  display: flex;
  justify-content: space-between;
}
.modal-popups .popup_heading h3 {
  margin-top: 0;
}
.modal-popups .popup_heading .popup_close {
  float: right;
  padding: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}
.modal-popups .popup_heading .popup_close:hover {
  background-color: rgb(255, 0, 0);
  color: rgb(255, 255, 255);
}
.modal-popups .popup_footer {
  margin-top: 10px;
}
.modal-popups .popup_footer footer button,
.modal-popups .popup_footer a {
  float: right;
  height: 30px;
  border: 2px solid #007592;
  background: none;
  padding: 0 20px;
  margin-right: 10px;
}
.modal-popups .popup_footer footer button:hover,
.modal-popups .popup_footer a:hover {
  background-color: #007592;
  color: rgb(255, 255, 255);
}
.modal-popups .popup_footer a {
  display: flex;
  align-items: center;
  border-radius: 0px;
}
.modal-popups body {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.modal-popups .guidance {
  color: rgb(141, 141, 141);
  margin: 16px;
}

.gis_map {
  display: none;
  flex: 1 1 0%;
  width: 100%;
  position: relative;
}
.gis_map .snapshot_btn {
  position: absolute;
  right: 50px;
  top: 1%;
}
.gis_map .snapshot_btn button {
  padding: 7px;
  width: 44px;
  height: 44px;
  background: #fff;
  border-radius: 5px;
  border: none;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.gis_map .snapshot_btn button svg {
  width: calc(20px / var(--scaleFactor));
  height: calc(20px / var(--scaleFactor));
}
.gis_map .toggle_freeDraw {
  width: 0;
  padding: 0;
}
.gis_map .toggle_freeDraw .drawTab {
  right: 0;
}
.gis_map .layerSearch {
  position: absolute;
  right: 50px;
  top: 0;
  z-index: 1;
  height: 40px;
  margin: 10px 10px 0 0;
  display: flex;
  align-items: center;
}
.gis_map .layerSearch .form-group {
  margin: 0;
}
.gis_map .layerSearch button {
  display: none;
  height: 35px;
  margin-right: 5px;
  width: 35px;
  align-items: center;
  justify-content: center;
  background: none;
  border: 2px solid #007592;
  border-radius: 50%;
}
.gis_map .layerSearch button svg {
  height: calc(20px / var(--scaleFactor));
  width: calc(20px / var(--scaleFactor));
  fill: #007592;
}
.gis_map .layerSearch select {
  height: 100%;
  border-radius: 6px;
}
.gis_map .layerSearch .toggle_shown {
  display: flex;
}

.saveDrawnLayer {
  height: calc(760px / var(--scaleFactor));
}
.saveDrawnLayer .input {
  margin-bottom: 5px;
}
.saveDrawnLayer .input input,
.saveDrawnLayer .input select {
  height: 45px !important;
  border-radius: 8px;
  width: 100%;
  border: none;
  background-color: #ededed;
}
.saveDrawnLayer .save_layerSettings {
  display: flex;
  justify-content: space-between;
}

.customLayerStyle {
  width: calc(800px / var(--scaleFactor));
  left: 32%;
}
.customLayerStyle .previewSettings {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0;
}
.customLayerStyle .previewSettings .stylePreview {
  height: 150px;
  width: 40%;
  border: 2px solid #007592;
  border-radius: 8px;
  display: flex;
}
.customLayerStyle .previewSettings .stylePreview .layerShape {
  margin: auto;
}
.customLayerStyle .previewSettings .stylePreview .point {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
.customLayerStyle .previewSettings .stylePreview .rectangle {
  height: 50px;
  width: 100px;
}
.customLayerStyle .previewSettings .stylePreview .line {
  width: 100px;
}
.customLayerStyle .groupedSettings {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.customLayerStyle .groupedSettings .styleSettings {
  border-radius: 8px;
  padding: 10px 0;
}
.customLayerStyle .groupedSettings .styleSettings .range {
  position: relative;
  padding: 15px;
}
.customLayerStyle .groupedSettings .styleSettings .range input {
  width: 295px;
  height: 4px;
  border-radius: 5px;
  background-color: #007592;
  outline: none;
}
.customLayerStyle .groupedSettings .styleSettings .range input::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #007592;
}
.customLayerStyle .groupedSettings .styleSettings .range span {
  position: absolute;
  top: 0;
  right: 0;
  margin-left: 14px;
  width: 50px;
  height: 30px;
  background-color: rgb(212, 212, 212);
  border-radius: 3px;
  text-align: center;
  line-height: 30px;
}
.customLayerStyle .advancedStyling {
  display: flex;
  flex-direction: column;
}
.customLayerStyle .advancedStyling .advancedStyling_content {
  display: none;
}
.customLayerStyle .advancedStyling .toggle_view {
  display: flex;
}

.groupLayerName {
  height: min(30% - 50px, 300px);
  width: min(40% - 50px, 400px);
}
.groupLayerName .popup_heading {
  position: relative;
}
.groupLayerName .popup_heading button {
  position: absolute;
  right: 20px;
}
.groupLayerName .input {
  margin: 20px;
}

.groupLayerName .input {
  margin-bottom: 5px;
}
.groupLayerName .input input,
.groupLayerName .input select {
  height: 45px !important;
  border-radius: 8px;
  width: 100%;
  border: 1px solid;
}

.leaflet-control-layers-expanded {
  font-size: 10px;
  line-height: 0;
  font-family: "Montserrat";
}

.leaflet-popup-content {
  font-size: 22px;
  font-family: "Montserrat";
}

.latlongtooltip {
  font-size: 10px;
  padding: 10px;
  font-family: "Montserrat";
}

.uploadCustomLayer .uploadBody {
  padding: 6px 12px;
  margin: 40px 0;
  background-color: rgba(211, 211, 211, 0.3294117647);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.uploadCustomLayer .uploadBody .multi_layer_upload {
  display: none;
}
.uploadCustomLayer .uploadBody .uploadSelectionBtns {
  display: flex;
  justify-content: space-between;
}
.uploadCustomLayer .uploadBody .uploadSelectionBtns button {
  width: 48%;
  height: 50px;
  margin: 20px 0;
  background-color: #009ec6;
  border: none;
  border-radius: 8px;
  color: white;
}
.uploadCustomLayer .uploadBody .uploadSelectionBtns button.active {
  background-color: #017d9d;
  border: 2px solid black;
}
.uploadCustomLayer .uploadBody .input {
  margin-bottom: 5px;
}
.uploadCustomLayer .uploadBody .input input,
.uploadCustomLayer .uploadBody .input select {
  height: 45px !important;
  border-radius: 8px;
  width: 100%;
  border: none;
}
.uploadCustomLayer .uploadBody .toggle_custom {
  display: block;
}

.saveLoad_popup {
  position: fixed;
  inset: 100px;
  background-color: white;
  padding: 16px;
  overflow-y: scroll;
}
.saveLoad_popup .popup_footer {
  margin-top: 10px;
}
.saveLoad_popup .popup_footer footer button,
.saveLoad_popup .popup_footer a {
  float: right;
  height: 30px;
  border: 2px solid #007592;
  background: none;
  padding: 0 20px;
  margin-right: 10px;
}
.saveLoad_popup .popup_footer footer button:hover,
.saveLoad_popup .popup_footer a:hover {
  background-color: #007592;
  color: rgb(255, 255, 255);
}
.saveLoad_popup .popup_footer a {
  display: flex;
  align-items: center;
  border-radius: 0px;
}
.saveLoad_popup .popup_heading {
  display: flex;
  justify-content: space-between;
}
.saveLoad_popup .popup_heading h3 {
  margin-top: 0;
}
.saveLoad_popup .popup_heading .popup_close {
  float: right;
  padding: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}
.saveLoad_popup .popup_heading .popup_close:hover {
  background-color: rgb(255, 0, 0);
  color: rgb(255, 255, 255);
}
.saveLoad_popup .saveLoad_span {
  display: block;
  opacity: 0;
  position: relative;
  top: 0;
  border-radius: 8px;
  padding: 5px;
  width: 98%;
  text-align: center;
  background: rgb(0, 196, 84);
  color: white;
}
.saveLoad_popup .saveLoad_span.toggle_display {
  animation-name: inout;
  animation-duration: 4s;
}
@keyframes inout {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.saveLoad_popup .popup_heading .popup_close {
  position: absolute;
  right: 16px;
  top: 16px;
}
.saveLoad_popup .popup_body .input {
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
}
.saveLoad_popup .popup_body .input input,
.saveLoad_popup .popup_body .input select {
  height: 42pt !important;
  border-radius: 8px;
  padding: 10px;
  width: 300pt;
  border: 2px solid #007592;
}
.saveLoad_popup .popup_body .input .toggle_missingInfoShake {
  border: 2px solid red;
  animation: shakeError 0.2s ease-in-out;
}
@keyframes shakeError {
  0% {
    margin-left: 0rem;
  }
  25% {
    margin-left: 0.5rem;
  }
  75% {
    margin-left: -0.5rem;
  }
  100% {
    margin-left: 0rem;
  }
}
.saveLoad_popup .popup_body .input .toggle_missingInfo {
  border: 2px solid red;
}
.saveLoad_popup .popup_body .saveCurrent_project {
  display: flex;
  justify-content: center;
  align-items: center;
}
.saveLoad_popup .popup_body .saveloadshare_action {
  background: #dedede;
  width: 35px;
  height: 35px;
  border: none;
  border-radius: 8px;
  margin-right: 8px;
}
.saveLoad_popup .popup_body .saveLoad_btn {
  background: none;
  border: 2px solid #007592;
  border-radius: 8px;
  width: 23%;
}
.saveLoad_popup .popup_body .saveLoad_btn:hover {
  background-color: rgba(0, 158, 198, 0.2549019608) !important;
}
.saveLoad_popup .projectComments {
  background: white;
  padding: 18px;
}
.saveLoad_popup .projectComments .commentBox_group {
  height: 70%;
  overflow-y: scroll;
}
.saveLoad_popup .projectComments .commentBox_group .commentBox {
  padding: 8px;
  margin: 16px;
  border-radius: 8px;
  background: rgba(0, 158, 198, 0.2549019608);
}
.saveLoad_popup .projectComments .newCommentBox {
  margin: 16px;
}
.saveLoad_popup .projectComments .newCommentBox .input {
  display: flex;
  flex-direction: column;
}
.saveLoad_popup .projectComments .newCommentBox .input textarea {
  height: 115px;
  margin-bottom: 8px;
  border-radius: 8px;
  border: 2px solid #009ec6;
  padding: 16px;
}
.saveLoad_popup .projectComments .newCommentBox footer button {
  border: 2px solid #007592;
  background-color: #007592;
  color: rgb(255, 255, 255);
  padding: 0 20px;
  margin-right: 10px;
}
.saveLoad_popup .projectComments .newCommentBox footer button:hover {
  color: black;
  background: none;
}

.shareWith_internalPopup {
  position: fixed;
  background: white;
  inset: 32%;
  padding: 16px;
  border-radius: 8px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.shareWith_internalPopup .popup_heading {
  display: flex;
  justify-content: space-between;
}
.shareWith_internalPopup .popup_heading h3 {
  margin-top: 0;
}
.shareWith_internalPopup .popup_heading .popup_close {
  position: absolute;
  right: 24px;
  padding: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}
.shareWith_internalPopup .popup_heading .popup_close:hover {
  background-color: rgb(255, 0, 0);
  color: rgb(255, 255, 255);
}
.shareWith_internalPopup .input {
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
}
.shareWith_internalPopup .input input,
.shareWith_internalPopup .input select {
  height: 42pt !important;
  border-radius: 8px;
  padding: 10px;
  width: 300pt;
  border: 2px solid #007592;
}
.shareWith_internalPopup .input .toggle_missingInfoShake {
  border: 2px solid red;
  animation: shakeError 0.2s ease-in-out;
}
@keyframes shakeError {
  0% {
    margin-left: 0rem;
  }
  25% {
    margin-left: 0.5rem;
  }
  75% {
    margin-left: -0.5rem;
  }
  100% {
    margin-left: 0rem;
  }
}
.shareWith_internalPopup .input .toggle_missingInfo {
  border: 2px solid red;
}
.shareWith_internalPopup .sharableUsers {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.shareWith_internalPopup .sharableUsers .sharableUsers_list {
  flex-wrap: wrap;
  width: 55%;
}
.shareWith_internalPopup .sharableUsers .sharableUsers_list label {
  margin: 0 8px;
}
.shareWith_internalPopup .popup_footer .share_button {
  border: 2px solid #007592;
  background-color: #007592;
  color: rgb(255, 255, 255);
  padding: 0 20px;
  margin-right: 10px;
}
.shareWith_internalPopup .popup_footer .share_button:hover {
  color: black;
  background: none;
}
.shareWith_internalPopup .popup_footer .cancel_button {
  border: 2px solid #007592;
  background: none;
  padding: 0 20px;
  margin-right: 10px;
}
.shareWith_internalPopup .popup_footer .cancel_button:hover {
  background-color: #007592;
  color: rgb(255, 255, 255);
}

.mapSnapshot_popup {
  width: calc(1500px / var(--scaleFactor));
  left: 12%;
}
.mapSnapshot_popup .popup_body {
  display: flex;
  position: relative;
  justify-content: space-between;
  height: 93%;
}
.mapSnapshot_popup .popup_body .input {
  margin-bottom: 5px;
}
.mapSnapshot_popup .popup_body .input input,
.mapSnapshot_popup .popup_body .input select {
  height: 45px !important;
  width: 100%;
  border-radius: 8px;
  border: 1px solid;
}
.mapSnapshot_popup .popup_body .snapshot_template {
  width: 70%;
}
.mapSnapshot_popup .popup_body .snapshot_template img {
  width: 100%;
}
.mapSnapshot_popup .popup_body .snapshot_settings {
  height: 100%;
  overflow: scroll;
}
.mapSnapshot_popup .popup_body .snapshot_settings h3 {
  text-transform: uppercase;
  writing-mode: vertical-rl;
  text-orientation: upright;
  font-size: 1.9rem;
  background: #9feed6;
  width: 50px;
  margin: 10px;
  margin-left: 0;
  padding-top: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.mapSnapshot_popup .popup_body .snapshot_settings .snapshot_sidePanel,
.mapSnapshot_popup .popup_body .snapshot_settings .snapshot_mapEdits {
  display: flex;
  margin-right: 10px;
  margin-top: 10px;
  padding: 6px 12px;
  border-radius: 8px;
  background-color: rgba(211, 211, 211, 0.3294117647);
}
.mapSnapshot_popup .popup_body .snapshot_settings .input_checkbox {
  display: flex;
  justify-content: space-evenly;
  padding: 6px 12px;
  margin-top: 10px;
  background-color: white;
  border-radius: 8px 8px 0 0;
}
.mapSnapshot_popup .popup_body .snapshot_settings .input_checkbox input {
  width: 7%;
}
.mapSnapshot_popup .popup_body .snapshot_settings .panel_onoff,
.mapSnapshot_popup .popup_body .snapshot_settings .border_onoff {
  border-radius: 8px;
}
.mapSnapshot_popup .popup_body .snapshot_settings .mini_input,
.mapSnapshot_popup .popup_body .snapshot_settings .snapshot_metaData,
.mapSnapshot_popup .popup_body .snapshot_settings .snapshot_layerKey,
.mapSnapshot_popup .popup_body .snapshot_settings .snapshot_subMap,
.mapSnapshot_popup .popup_body .snapshot_settings .gridline_input,
.mapSnapshot_popup .popup_body .snapshot_settings .snapshot_scaleBar,
.mapSnapshot_popup .popup_body .snapshot_settings .snapshot_northArrow {
  display: none;
  padding: 6px 12px;
  border: 3px solid white;
  border-radius: 0 0 8px 8px;
}
.mapSnapshot_popup .popup_body .snapshot_settings .mini_input .snap_keyEdit,
.mapSnapshot_popup .popup_body .snapshot_settings .mini_input .snap_subMap,
.mapSnapshot_popup .popup_body .snapshot_settings .snapshot_metaData .snap_keyEdit,
.mapSnapshot_popup .popup_body .snapshot_settings .snapshot_metaData .snap_subMap,
.mapSnapshot_popup .popup_body .snapshot_settings .snapshot_layerKey .snap_keyEdit,
.mapSnapshot_popup .popup_body .snapshot_settings .snapshot_layerKey .snap_subMap,
.mapSnapshot_popup .popup_body .snapshot_settings .snapshot_subMap .snap_keyEdit,
.mapSnapshot_popup .popup_body .snapshot_settings .snapshot_subMap .snap_subMap,
.mapSnapshot_popup .popup_body .snapshot_settings .gridline_input .snap_keyEdit,
.mapSnapshot_popup .popup_body .snapshot_settings .gridline_input .snap_subMap,
.mapSnapshot_popup .popup_body .snapshot_settings .snapshot_scaleBar .snap_keyEdit,
.mapSnapshot_popup .popup_body .snapshot_settings .snapshot_scaleBar .snap_subMap,
.mapSnapshot_popup .popup_body .snapshot_settings .snapshot_northArrow .snap_keyEdit,
.mapSnapshot_popup .popup_body .snapshot_settings .snapshot_northArrow .snap_subMap {
  width: 100%;
  padding: 10px;
  background-color: #007592;
  color: white;
  border: none;
  border-radius: 8px;
}
.mapSnapshot_popup .popup_body .snapshot_settings .toggle_view {
  display: block;
}
.mapSnapshot_popup .popup_body .snapshot_insetMap {
  display: none;
  position: absolute;
  background: #fff;
  padding: 30px;
  margin: calc(50px / var(--scaleFactor)) calc(107px / var(--scaleFactor));
  width: 85%;
  z-index: 1006;
}
.mapSnapshot_popup .popup_body .snapshot_insetMap .snapshot_insetMapHeading {
  display: flex;
  justify-content: space-between;
}
.mapSnapshot_popup .popup_body .snapshot_insetMap .snapshot_insetMapHeading .popup_close {
  float: right;
  padding: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}
.mapSnapshot_popup .popup_body .snapshot_insetMap .snapshot_insetMapHeading .popup_close:hover {
  background-color: rgb(255, 0, 0);
  color: rgb(255, 255, 255);
}
.mapSnapshot_popup .popup_body .snapshot_insetMap footer {
  margin-top: 10px;
}
.mapSnapshot_popup .popup_body .snapshot_insetMap footer footer button,
.mapSnapshot_popup .popup_body .snapshot_insetMap footer a {
  float: right;
  height: 30px;
  border: 2px solid #007592;
  background: none;
  padding: 0 20px;
  margin-right: 10px;
}
.mapSnapshot_popup .popup_body .snapshot_insetMap footer footer button:hover,
.mapSnapshot_popup .popup_body .snapshot_insetMap footer a:hover {
  background-color: #007592;
  color: rgb(255, 255, 255);
}
.mapSnapshot_popup .popup_body .snapshot_insetMap footer a {
  display: flex;
  align-items: center;
  border-radius: 0px;
}
.mapSnapshot_popup .popup_body .popupOverlay {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #999;
  opacity: 0.8;
  z-index: 1005;
}

.persistence_output .popup_body .probability_output {
  padding: 20px;
  margin: 20px 0;
  border-radius: 8px;
}
.persistence_output .popup_body .probability_output .pre_optionsBtns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
}
.persistence_output .popup_body .probability_output .per_outputGraphs {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 10px;
}
.persistence_output .popup_body .probability_output .per_outputGraphs .shiny-html-output {
  width: 1000px;
  overflow-x: scroll;
}
.persistence_output .popup_body .probability_output .per_outputGraphs .plotly {
  margin-left: 25px;
}

.deleteLayer {
  height: min(40% - 50px, 200px);
  width: min(35% - 50px, 380px);
}
.deleteLayer .popup_heading {
  position: relative;
}
.deleteLayer .popup_heading button {
  position: absolute;
  right: 20px;
}
.deleteLayer .input {
  margin: 20px;
}
.deleteLayer .popup_body {
  display: flex;
  justify-content: space-evenly;
  margin-top: 48px;
}
.deleteLayer .popup_body button {
  float: right;
  height: 30px;
  border: 2px solid #007592;
  background: none;
  padding: 0 20px;
  margin-right: 10px;
}
.deleteLayer .popup_body button:hover {
  background-color: #007592;
  color: rgb(255, 255, 255);
}

.deleteWarning {
  height: min(40% - 50px, 300px);
  width: min(40% - 50px, 350px);
}
.deleteWarning .popup_heading {
  position: relative;
}
.deleteWarning .popup_heading svg {
  width: 25px;
  margin-right: 25px;
}
.deleteWarning .popup_body {
  display: flex;
  justify-content: space-evenly;
  margin-top: 25px;
}
.deleteWarning footer button {
  float: right;
  height: 30px;
  border: 2px solid #007592;
  background: none;
  padding: 0 20px;
  margin-right: 10px;
}
.deleteWarning footer button:hover {
  background-color: #007592;
  color: rgb(255, 255, 255);
}

.saveOnClose_popup {
  height: min(20% - 50px, 250px);
  width: min(70% - 50px, 700px);
}

#help-popup .saveLoad_span {
  display: block;
  opacity: 0;
  position: absolute;
  top: 16px;
  left: 80px;
  border-radius: 8px;
  padding: 5px;
  width: 90%;
  text-align: center;
  background: rgb(0, 196, 84);
  color: white;
}
#help-popup .saveLoad_span.toggle_display {
  animation-name: inout;
  animation-duration: 4s;
}
@keyframes inout {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.privacyandcookies {
  height: min(55% - 50px, 360px);
  width: min(70% - 50px, 800px);
}

.siteLoad_popup {
  height: calc(300px / var(--scaleFactor));
}

.vakata-context {
  z-index: 1500;
}

.toggle_mainPane {
  display: block;
}

.vektaStore .popup_heading {
  display: flex;
}
.vektaStore .popup_heading h3 {
  margin-top: 0;
}
.vektaStore .popup_heading .vektaStoreProfile {
  width: 50px;
}
.vektaStore .popup_heading .vektaStoreProfile svg {
  font-size: xx-large;
}
.vektaStore .popup_heading .vektaStore_controlBtns {
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 310px;
  width: 200pt;
  height: 47px;
}
.vektaStore .popup_heading .vektaStore_controlBtns button {
  height: 100%;
  width: 90pt;
}
.vektaStore .popup_heading .popup_close {
  position: absolute;
  right: 40px;
  padding: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}
.vektaStore .popup_heading .popup_close:hover {
  background-color: rgb(255, 0, 0);
  color: rgb(255, 255, 255);
}
.vektaStore .popup_body .toolsViewTab {
  display: none;
}
.vektaStore .popup_body .toolsViewTab .vektaStore_tool {
  background: #f5f5f5;
  padding: 16px;
  margin: 16px;
  width: 270pt;
  border-radius: 8px;
}
.vektaStore .popup_body .toolsViewTab .vektaStore_tool .toolPricing {
  display: flex;
  align-items: flex-end;
  margin-top: 32px;
}
.vektaStore .popup_body .toolsViewTab .vektaStore_tool .toolPricing p {
  margin-left: 8px;
}
.vektaStore .popup_body .toolsViewTab.toggle_group {
  display: flex;
  flex-direction: column;
}

.popup_close_PandC {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}
.popup_close_PandC:hover {
  background-color: rgb(255, 0, 0);
  color: rgb(255, 255, 255);
}

.sidePanel_body .input {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}
.sidePanel_body .input input,
.sidePanel_body .input select {
  height: 48pt !important;
  border-radius: 8px;
  padding: 10px;
  width: 340pt;
  border: 2px solid #007592;
}
.sidePanel_body .input .toggle_missingInfoShake {
  border: 2px solid red;
  animation: shakeError 0.2s ease-in-out;
}
@keyframes shakeError {
  0% {
    margin-left: 0rem;
  }
  25% {
    margin-left: 0.5rem;
  }
  75% {
    margin-left: -0.5rem;
  }
  100% {
    margin-left: 0rem;
  }
}
.sidePanel_body .input .toggle_missingInfo {
  border: 2px solid red;
}

.main_sidebar {
  display: flex;
  justify-content: space-between;
  width: 0%;
  padding: 0;
  overflow-y: scroll !important;
  overflow-x: hidden;
  height: 100vh;
  background-color: rgb(255, 255, 255);
  box-shadow: 8px 0px 20px 2px rgba(204, 204, 204, 0.5294117647);
}
.main_sidebar .resizeButtons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 5%;
  margin: 4px;
}
.main_sidebar .resizeButtons button {
  font-weight: 900;
  font-size: 20px;
  background: none;
  border: none;
  color: #007592;
}
.main_sidebar .resizeButtons button:hover {
  background-color: rgba(219, 219, 219, 0.22);
  border-radius: 8px;
}
.main_sidebar .widgets {
  height: 100%;
  width: 93%;
  padding: 16px 0 16px 16px;
  flex-direction: column;
}
.main_sidebar .widgets .widgetTour {
  margin-left: 10px;
  border: #adadad 1.5px solid;
  width: 30px;
  height: 30px;
}
.main_sidebar .widgets.main_sidebarLoader_wrapper {
  height: -moz-fit-content;
  height: fit-content;
  min-height: 100%;
  position: relative;
  padding: 16px 0 16px 16px;
}
.main_sidebar .widgets.main_sidebarLoader_wrapper .main_sidebarLoader_overlay {
  background: rgba(180, 180, 180, 0.527);
}
.main_sidebar .widgets.main_sidebarLoader_wrapper .main_sidebarLoader_content {
  position: fixed;
  top: 50%;
  left: 25rem;
  transform: translate(0, -50%);
}
.main_sidebar .widgets hr {
  margin: 8px 0 0 0;
  border-top: 1.5px solid grey;
  width: 100%;
}
.main_sidebar .widgets .input {
  margin-bottom: 5px;
}
.main_sidebar .widgets .input input,
.main_sidebar .widgets .input select {
  height: 45px !important;
  border-radius: 8px;
  width: 100%;
  border: none;
}
.main_sidebar .widgets .input .toggle_missingInfoShake {
  animation: shakeError 0.2s ease-in-out 0s 2;
}
@keyframes shakeError {
  0% {
    margin-left: 0rem;
  }
  25% {
    margin-left: 0.5rem;
  }
  75% {
    margin-left: -0.5rem;
  }
  100% {
    margin-left: 0rem;
  }
}
.main_sidebar .widgets .input .toggle_missingInfo {
  border: 2px solid red;
}
.main_sidebar .widgets .inputSection {
  background-color: rgba(211, 211, 211, 0.3294117647);
  border-radius: 8px;
  padding: 16px;
  margin: 16px 0;
}
.main_sidebar .widgets .inputSection .inputSection_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 8px;
  color: white;
  background: #00A0C6;
}
.main_sidebar .widgets .inputSection .inputSection_heading h4 {
  margin: 15px;
}
.main_sidebar .widgets .inputSection .inputSection_heading svg {
  width: 25px;
  margin-right: 10px;
  fill: white;
}
.main_sidebar .widgets .inputSection .inputSection_heading .inputControlsHide {
  background: none;
  border: none;
  outline: none;
  height: 4em;
  width: 4em;
  display: block;
  padding: 0.5em;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 15px;
}
.main_sidebar .widgets .inputSection .inputSection_heading .inputControlsHide .left-bar {
  position: absolute;
  background-color: transparent;
  top: 18px;
  left: 0;
  width: 20px;
  height: 4px;
  display: block;
  transform: rotate(35deg);
  float: right;
  border-radius: 2px;
}
.main_sidebar .widgets .inputSection .inputSection_heading .inputControlsHide .left-bar:after {
  content: "";
  background-color: white;
  width: 20px;
  height: 4px;
  display: block;
  float: right;
  border-radius: 6px 10px 10px 6px;
  transition: all 1s cubic-bezier(0.25, 1.7, 0.35, 0.8);
  z-index: -1;
}
.main_sidebar .widgets .inputSection .inputSection_heading .inputControlsHide .right-bar {
  position: absolute;
  background-color: transparent;
  top: 18px;
  left: 14px;
  width: 20px;
  height: 4px;
  display: block;
  transform: rotate(-35deg);
  float: right;
  border-radius: 2px;
}
.main_sidebar .widgets .inputSection .inputSection_heading .inputControlsHide .right-bar:after {
  content: "";
  background-color: white;
  width: 20px;
  height: 4px;
  display: block;
  float: right;
  border-radius: 10px 6px 6px 10px;
  transition: all 1s cubic-bezier(0.25, 1.7, 0.35, 0.8);
  z-index: -1;
}
.main_sidebar .toggle_content {
  display: flex;
}
.main_sidebar .toggle_content .project_heading {
  display: none;
}
.main_sidebar .layersControls {
  overflow-y: hidden;
}
.main_sidebar .layersControls .project_layers {
  height: 100%;
}
.main_sidebar .layersControls .project_layers .open .left-bar:after {
  transform-origin: center center;
  transform: rotate(-70deg);
}
.main_sidebar .layersControls .project_layers .open .right-bar:after {
  transform-origin: center center;
  transform: rotate(70deg);
}
.main_sidebar .layersControls .project_layers .tree {
  height: 55%;
  max-height: 60%;
  overflow-x: scroll;
}
.main_sidebar .layersControls .project_layers .insightSearch .insightSearch_parameters #insightsResultsBox {
  max-height: 350px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.main_sidebar .layersControls .project_layers .project_heading {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.main_sidebar .layersControls .project_layers .project_heading .layer_controlBtns {
  display: flex;
  align-items: center;
}
.main_sidebar .layersControls .project_layers .project_heading .layer_controlBtns .vl {
  border-left: 3px solid #007592;
  height: 30px;
  margin: 0 3px;
}
.main_sidebar .layersControls .project_layers .project_heading .layer_controlBtns button {
  border: none;
  border-radius: 8px;
  padding: 5px;
  margin: 0;
}
.main_sidebar .layersControls .project_layers .project_heading .layer_controlBtns button svg {
  width: 18px;
  height: 18px;
}
.main_sidebar .layersControls .project_layers .project_heading .layer_controlBtns button:hover {
  background-color: #00A0C6;
}
.main_sidebar .layersControls .project_layers .project_heading .layer_controlBtns button:hover svg {
  color: white;
  fill: white;
}
.main_sidebar .layersControls .project_layers .project_heading .layer_controlBtns button i {
  font-size: large;
}
.main_sidebar .layersControls .project_layers .project_heading .layer_controlBtns button.Mui-selected {
  background-color: #00A0C6;
  color: white;
  fill: white;
}
.main_sidebar .layersControls .project_layers .project_heading .layer_controlBtns .showHideController {
  display: inline-block;
  position: relative;
}
.main_sidebar .layersControls .project_layers .project_heading .layer_controlBtns .showHideController button svg {
  width: 21px;
}
.main_sidebar .layersControls .project_layers .project_heading .layer_controlBtns .showHideController .showHideSubOptions {
  position: absolute;
  right: 0;
  z-index: 100;
  width: 120px;
  overflow: auto;
  background-color: #ffffff;
  border: 2px solid #00A0C6;
  padding: 5px;
  border-radius: 8px;
}
.main_sidebar .layersControls .project_layers .project_heading .layer_controlBtns .showHideController .showHideSubOptions a {
  display: block;
}
.main_sidebar .layersControls .project_layers .project_heading .layer_controlBtns .showHideController .openSubMenu {
  display: block;
}
.main_sidebar .layersControls .project_layers .projectTree {
  height: 85%;
  border-radius: 8px;
  padding: 5px;
  margin-top: 16px;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: 1.5s cubic-bezier(0, -1.37, 0, 1.2);
}
.main_sidebar .layersControls .project_layers .projectTree .globalLayers,
.main_sidebar .layersControls .project_layers .projectTree .ukLayers {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main_sidebar .layersControls .project_layers .projectTree .globalLayers .globalLayers_search,
.main_sidebar .layersControls .project_layers .projectTree .globalLayers .ukLayers_search,
.main_sidebar .layersControls .project_layers .projectTree .ukLayers .globalLayers_search,
.main_sidebar .layersControls .project_layers .projectTree .ukLayers .ukLayers_search {
  width: 45%;
  position: relative;
}
.main_sidebar .layersControls .project_layers .projectTree .globalLayers .globalLayers_search span,
.main_sidebar .layersControls .project_layers .projectTree .globalLayers .ukLayers_search span,
.main_sidebar .layersControls .project_layers .projectTree .ukLayers .globalLayers_search span,
.main_sidebar .layersControls .project_layers .projectTree .ukLayers .ukLayers_search span {
  position: absolute;
  right: 15px;
  top: 6px;
}
.main_sidebar .layersControls .project_layers .projectTree .globalLayers .globalLayers_search input,
.main_sidebar .layersControls .project_layers .projectTree .globalLayers .ukLayers_search input,
.main_sidebar .layersControls .project_layers .projectTree .ukLayers .globalLayers_search input,
.main_sidebar .layersControls .project_layers .projectTree .ukLayers .ukLayers_search input {
  border-radius: 8px;
  outline: none;
  border: 2px solid #cccccc;
  height: 3.5rem;
  width: 100%;
}
.main_sidebar .layersControls .project_layers .projectTree .projectJSTree {
  height: 100%;
}
.main_sidebar .layersControls .project_layers .projectTree .projectJSTree .jstree-clicked {
  background: #007592 !important;
}
.main_sidebar .layersControls .project_layers .projectTree .projectJSTree .jstree-hovered {
  background: #00A0C6 !important;
}
.main_sidebar .layersControls .project_layers .projectTree .projectJSTree .jstree-search {
  color: #00A0C6 !important;
}
.main_sidebar .layersControls .project_layers .projectTree .projectJSTree .jstree-rename-input {
  color: black;
}
.main_sidebar .layersControls .project_layers .projectTree .projectJSTree .jstree-default a {
  white-space: normal !important;
  height: auto;
}
.main_sidebar .layersControls .project_layers .projectTree .projectJSTree .jstree-anchor {
  height: auto !important;
}
.main_sidebar .layersControls .project_layers .projectTree .projectJSTree .jstree-default li > ins {
  vertical-align: top;
}
.main_sidebar .layersControls .project_layers .projectTree .projectJSTree .jstree-leaf {
  height: auto;
}
.main_sidebar .layersControls .project_layers .projectTree .projectJSTree .jstree-leaf a {
  height: auto !important;
  white-space: initial;
}
.main_sidebar .layersControls .project_layers .projectTree .sizingContainer .rhandsontable {
  height: 450px !important;
}
.main_sidebar .layersControls .project_layers .projectTree .sizingContainer .rhandsontable .wtHolder {
  height: 450px !important;
}
.main_sidebar .layersControls .project_layers .extend_section {
  height: 83%;
}
.main_sidebar .customLayers {
  height: 95%;
}
.main_sidebar .customLayers hr {
  margin-top: 0;
}
.main_sidebar .customLayers .customOptions {
  display: flex;
  justify-content: space-between;
}
.main_sidebar .customLayers .customOptions button {
  width: 49%;
  height: 60px;
  background: none;
  border: none;
  border-radius: 8px 8px 0 0;
  background-color: #d3d3d3;
}
.main_sidebar .customLayers .customOptions button.active {
  background-color: rgba(211, 211, 211, 0.3294117647);
  text-decoration: underline;
  text-decoration-color: #9feed6;
  text-decoration-thickness: 3px;
}
.main_sidebar .customLayers .draw_layer_settings,
.main_sidebar .customLayers .upload_layer_settings {
  display: none;
  padding: 6px 12px;
  background-color: rgba(211, 211, 211, 0.3294117647);
  border-radius: 0 0 8px 8px;
}
.main_sidebar .customLayers .draw_layer_settings h3 {
  margin-bottom: 10px;
  font-size: 20px;
}
.main_sidebar .customLayers .draw_layer_settings .drawBtns {
  display: flex;
  height: 50px;
}
.main_sidebar .customLayers .draw_layer_settings .drawBtns button {
  margin: 0;
}
.main_sidebar .customLayers .draw_layer_settings .point {
  border-radius: 8px;
  padding: 10px 0;
  margin: 10px 0;
}
.main_sidebar .customLayers .draw_layer_settings .point .range {
  position: relative;
  padding: 15px;
}
.main_sidebar .customLayers .draw_layer_settings .point .range input {
  -webkit-appearance: none;
  width: 295px;
  height: 4px;
  border-radius: 5px;
  background-color: #007592;
  outline: none;
}
.main_sidebar .customLayers .draw_layer_settings .point .range input::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #007592;
}
.main_sidebar .customLayers .draw_layer_settings .point .range span {
  position: absolute;
  top: 0;
  right: 0;
  margin-left: 14px;
  width: 50px;
  height: 30px;
  background-color: rgb(212, 212, 212);
  border-radius: 3px;
  text-align: center;
  line-height: 30px;
}
.main_sidebar .customLayers .upload_layer_settings h3 {
  margin-bottom: 10px;
  font-size: 20px;
}
.main_sidebar .customLayers .upload_layer_settings .multi_layer_upload {
  display: none;
}
.main_sidebar .customLayers .upload_layer_settings .uploadSelectionBtns {
  display: flex;
  justify-content: space-around;
}
.main_sidebar .customLayers .upload_layer_settings .uploadSelectionBtns button {
  width: 40%;
}
.main_sidebar .customLayers .upload_layer_settings .shiny-input-container {
  width: 100%;
}
.main_sidebar .customLayers .custom_layers_edit .layer_edit_btns {
  display: flex;
  justify-content: space-evenly;
}
.main_sidebar .customLayers .custom_layers_edit .layer_edit_btns button {
  color: white;
  background-color: #00A0C6;
  border: none;
  border-radius: 8px;
  padding: 15px;
}
.main_sidebar .customLayers .custom_layers_edit .layer_edit_btns button:hover {
  background-color: #007592;
}
.main_sidebar .customLayers .custom_layers_edit h3 {
  margin-bottom: 10px;
  font-size: 20px;
}
.main_sidebar .customLayers .toggle_custom {
  display: block;
}
.main_sidebar .weatherAnalysis .windheightinput {
  padding-top: 10px;
}
.main_sidebar .weatherAnalysis .open .left-bar:after {
  transform-origin: center center;
  transform: rotate(-70deg);
}
.main_sidebar .weatherAnalysis .open .right-bar:after {
  transform-origin: center center;
  transform: rotate(70deg);
}
.main_sidebar .weatherAnalysis .weatherInputs .selectDataPoint {
  display: none;
  flex-direction: column;
  margin: 35px;
}
.main_sidebar .weatherAnalysis .weatherInputs .selectDataPoint .promt {
  display: flex;
  justify-content: space-between;
}
.main_sidebar .weatherAnalysis .weatherInputs .selectDataPoint svg {
  width: 25px;
}
.main_sidebar .weatherAnalysis .weatherInputs .uploadWeather {
  display: none;
  margin: 20px 0;
}
.main_sidebar .weatherAnalysis .weatherInputs .uploadWeather .shinyUpload .form-group {
  margin-bottom: 0;
}
.main_sidebar .weatherAnalysis .weatherInputs .uploadWeather .shinyUpload .form-group .progress {
  margin-bottom: 0;
}
.main_sidebar .weatherAnalysis .weatherInputs .uploadWeather .shinyUpload label {
  width: 35%;
}
.main_sidebar .weatherAnalysis .weatherInputs .uploadWeather .shinyUpload input {
  height: 34px;
}
.main_sidebar .weatherAnalysis .weatherInputs .uploadWeather .shiny-html-output {
  color: red;
}
.main_sidebar .weatherAnalysis .weatherInputs .toggle_weather {
  display: flex;
}
.main_sidebar .weatherAnalysis .weatherOutput .shiny-text-output {
  font-weight: 500;
  line-height: 1.1;
  font-size: 18px;
  text-transform: uppercase;
  color: white;
  background-color: #009ec6;
  margin: 10px auto;
  width: -moz-max-content;
  width: max-content;
  padding: 15px;
  border-radius: 8px;
}
.main_sidebar .weatherAnalysis .weatherOutput img {
  width: 100% !important;
}
.main_sidebar .weatherAnalysis .weatherDownload {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}
.main_sidebar .weatherAnalysis .weatherDownload .disabledButton {
  background-color: grey;
  cursor: not-allowed;
}
.main_sidebar .weatherAnalysis .weatherDownload .disabledButton:hover svg {
  fill: white;
}
.main_sidebar .weatherAnalysis .yieldDownload {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}
.main_sidebar .weatherAnalysis .yieldDownload .disabledButton {
  background-color: grey;
  cursor: not-allowed;
}
.main_sidebar .weatherAnalysis .yieldDownload .disabledButton:hover svg {
  fill: white;
}
.main_sidebar .weatherAnalysis .persistenceInput .multiInput {
  margin-top: 10px;
}
.main_sidebar .weatherAnalysis .persistenceInput .multiInput .input {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.main_sidebar .weatherAnalysis .persistenceInput .multiInput .input .labelInput {
  width: 100%;
}
.main_sidebar .weatherAnalysis .persistenceInput .multiInput .input .unitInput {
  display: flex;
  width: 70%;
}
.main_sidebar .weatherAnalysis .persistenceInput .multiInput .input button {
  border: 2px solid #007592;
  border-radius: 8px;
  width: 23%;
}
.main_sidebar .weatherAnalysis .persistenceInput .multiInput .input button:hover {
  background-color: rgba(0, 158, 198, 0.2549019608);
}
.main_sidebar .weatherAnalysis .persistenceInput .multiInput .persistenceOption {
  width: 100%;
}
.main_sidebar .weatherAnalysis .persistenceInput .multiInput .multiLabel {
  margin: 0;
  color: #8d8d8d;
}
.main_sidebar .weatherAnalysis .per_buttons {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.main_sidebar .weatherAnalysis .per_buttons button {
  width: 40%;
  padding: 6px 12px;
  margin: 10px 0 20px 0;
  border: none;
  border-radius: 4px;
  background-color: #009ec6;
  color: white;
}
.main_sidebar .weatherAnalysis .per_buttons .disabledButton {
  background-color: grey;
  cursor: not-allowed;
}
.main_sidebar .weatherAnalysis .hide_block {
  display: none;
}
.main_sidebar .elevationAnalysis {
  overflow-y: scroll;
  overflow-x: hidden;
}
.main_sidebar .elevationAnalysis .gis_includeSlope label {
  text-align: right;
}
.main_sidebar .elevationAnalysis .waterDepthDraw .promt {
  display: flex;
  justify-content: space-between;
  margin: 35px 3px 0 35px;
}
.main_sidebar .elevationAnalysis .waterDepthDraw .promt svg {
  width: 24px;
}
.main_sidebar .elevationAnalysis .waterDepthDraw .elevationLimitation {
  display: flex;
  justify-content: space-around;
}
.main_sidebar .elevationAnalysis .waterDepthDraw .elevationAnalysis_point {
  background-color: rgba(211, 211, 211, 0.3294117647);
  border-radius: 8px;
  padding: 16px;
}
.main_sidebar .elevationAnalysis .waterDepthDraw .elevationAnalysis_point .pointData {
  display: flex;
  justify-content: space-around;
  background-color: #007592;
  padding: 16px;
  border-radius: 8px;
  color: white;
}
.main_sidebar .elevationAnalysis .waterDepthDraw .inputSection_body {
  margin-top: 16px;
}
.main_sidebar .elevationAnalysis .waterDepthDraw .additionalDrawBtns {
  display: flex;
  justify-content: space-between;
}
.main_sidebar .elevationAnalysis .waterDepthDraw .additionalDrawBtns button {
  width: 70px;
  height: 40px;
  background-color: #009ec6;
  color: white;
  border-radius: 8px;
  border: none;
}
.main_sidebar .elevationAnalysis .waterDepthDraw .additionalDrawBtns .input input {
  margin: 0;
}
.main_sidebar .elevationAnalysis .waterDepthDraw .additionalDrawBtns .input label {
  margin: 0;
}
.main_sidebar .elevationAnalysis .downloadWaterDepth a {
  width: 100%;
  margin: 10px 0 20px 0;
  background-color: #00A0C6;
  color: white;
}
.main_sidebar .elevationAnalysis .waterDepthBoundingBox {
  padding: 6px 12px;
  background-color: rgba(211, 211, 211, 0.3294117647);
  border-radius: 0 0 8px 8px;
  margin-top: 10px;
}
.main_sidebar .elevationAnalysis .waterDepthPoint {
  display: none;
}
.main_sidebar .elevationAnalysis .waterDepthPoint .shiny-text-output {
  font-weight: 500;
  line-height: 1.1;
  font-size: 18px;
  text-transform: uppercase;
  color: white;
  background-color: #009ec6;
  margin: 10px auto;
  width: -moz-max-content;
  width: max-content;
  padding: 15px;
  border-radius: 8px;
}
.main_sidebar .elevationAnalysis .waterDepthPoint img {
  width: 100% !important;
}
.main_sidebar .elevationAnalysis .toggle_pointDepth {
  display: block;
}
.main_sidebar .elevationAnalysis .waterDepthGraph #scene {
  width: 100% !important;
  left: 0 !important;
}
.main_sidebar .elevationAnalysis .waterDepthGraph .area_waterSlope {
  display: none;
}
.main_sidebar .elevationAnalysis .waterDepthGraph .area_waterDepthBound {
  display: none;
}
.main_sidebar .elevationAnalysis .waterDepthGraph .toggle_areaWater {
  display: block;
}
.main_sidebar .widgetOverlay {
  display: none;
  background: #999;
  opacity: 0.8;
  position: absolute;
  z-index: 1001;
  top: 0;
  left: 0;
  height: 100%;
  width: 30vw;
}
.main_sidebar .dataSearch {
  overflow-y: scroll;
  overflow-x: hidden;
}
.main_sidebar .dataSearch .portSearch .inputSection_heading span,
.main_sidebar .dataSearch .aisSearch .inputSection_heading span {
  margin: 0 5px;
}
.main_sidebar .dataSearch .portSearch .open .left-bar:after,
.main_sidebar .dataSearch .aisSearch .open .left-bar:after {
  transform-origin: center center;
  transform: rotate(-70deg);
}
.main_sidebar .dataSearch .portSearch .open .right-bar:after,
.main_sidebar .dataSearch .aisSearch .open .right-bar:after {
  transform-origin: center center;
  transform: rotate(70deg);
}
.main_sidebar .dataSearch .portSearch .hide_block,
.main_sidebar .dataSearch .aisSearch .hide_block {
  display: none;
}
.main_sidebar .dataSearch .portSearch .portSearch_parameters .groupedInputs,
.main_sidebar .dataSearch .portSearch .aisSearch_parameters .groupedInputs,
.main_sidebar .dataSearch .aisSearch .portSearch_parameters .groupedInputs,
.main_sidebar .dataSearch .aisSearch .aisSearch_parameters .groupedInputs {
  display: flex;
  justify-content: space-between;
}
.main_sidebar .dataSearch .portSearch .portSearch_parameters .groupedInputs .input,
.main_sidebar .dataSearch .portSearch .aisSearch_parameters .groupedInputs .input,
.main_sidebar .dataSearch .aisSearch .portSearch_parameters .groupedInputs .input,
.main_sidebar .dataSearch .aisSearch .aisSearch_parameters .groupedInputs .input {
  width: 30%;
}
.main_sidebar .dataSearch .portSearch .portSearch_parameters .portLifts_inputs,
.main_sidebar .dataSearch .portSearch .portSearch_parameters .portCrane_inputs,
.main_sidebar .dataSearch .portSearch .aisSearch_parameters .portLifts_inputs,
.main_sidebar .dataSearch .portSearch .aisSearch_parameters .portCrane_inputs,
.main_sidebar .dataSearch .aisSearch .portSearch_parameters .portLifts_inputs,
.main_sidebar .dataSearch .aisSearch .portSearch_parameters .portCrane_inputs,
.main_sidebar .dataSearch .aisSearch .aisSearch_parameters .portLifts_inputs,
.main_sidebar .dataSearch .aisSearch .aisSearch_parameters .portCrane_inputs {
  display: flex;
  justify-content: space-between;
}
.main_sidebar .dataSearch .portSearch .portSearch_parameters .portLifts_inputs .input,
.main_sidebar .dataSearch .portSearch .portSearch_parameters .portCrane_inputs .input,
.main_sidebar .dataSearch .portSearch .aisSearch_parameters .portLifts_inputs .input,
.main_sidebar .dataSearch .portSearch .aisSearch_parameters .portCrane_inputs .input,
.main_sidebar .dataSearch .aisSearch .portSearch_parameters .portLifts_inputs .input,
.main_sidebar .dataSearch .aisSearch .portSearch_parameters .portCrane_inputs .input,
.main_sidebar .dataSearch .aisSearch .aisSearch_parameters .portLifts_inputs .input,
.main_sidebar .dataSearch .aisSearch .aisSearch_parameters .portCrane_inputs .input {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.main_sidebar .dataSearch .portSearch .portSearch_parameters .portShortList,
.main_sidebar .dataSearch .portSearch .aisSearch_parameters .portShortList,
.main_sidebar .dataSearch .aisSearch .portSearch_parameters .portShortList,
.main_sidebar .dataSearch .aisSearch .aisSearch_parameters .portShortList {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main_sidebar .dataSearch .portSearch .portSearch_parameters .portShortList .input,
.main_sidebar .dataSearch .portSearch .aisSearch_parameters .portShortList .input,
.main_sidebar .dataSearch .aisSearch .portSearch_parameters .portShortList .input,
.main_sidebar .dataSearch .aisSearch .aisSearch_parameters .portShortList .input {
  width: 100%;
}
.main_sidebar .dataSearch .portSearch .portSearch_parameters .portSearchTable,
.main_sidebar .dataSearch .portSearch .aisSearch_parameters .portSearchTable,
.main_sidebar .dataSearch .aisSearch .portSearch_parameters .portSearchTable,
.main_sidebar .dataSearch .aisSearch .aisSearch_parameters .portSearchTable {
  display: none;
  height: 31%;
  overflow: scroll;
}
.main_sidebar .dataSearch .portSearch .portSearch_parameters .toggle_table,
.main_sidebar .dataSearch .portSearch .aisSearch_parameters .toggle_table,
.main_sidebar .dataSearch .aisSearch .portSearch_parameters .toggle_table,
.main_sidebar .dataSearch .aisSearch .aisSearch_parameters .toggle_table {
  display: block;
}

.lessSize {
  width: min(35% - 50px, 500px);
}

.middleSize {
  width: min(45% - 50px, 800px);
}

.moreSize {
  width: min(65% - 50px, 1100px);
}

.databaseCenter {
  display: none;
  width: 100%;
  position: relative;
}
.databaseCenter .databaseOptions_Btns button {
  padding: 0;
  margin: 10px;
  position: relative;
  border: none;
  border-radius: 8px;
}
.databaseCenter .databaseOptions_Btns button .moving {
  width: calc(715px / var(--scaleFactor));
  height: calc(412px / var(--scaleFactor));
  border-radius: 8px;
}
.databaseCenter .databaseOptions_Btns button .still {
  width: calc(715px / var(--scaleFactor));
  height: calc(412px / var(--scaleFactor));
  position: absolute;
  left: 0;
  filter: grayscale(1);
  border-radius: 8px;
}
.databaseCenter .databaseOptions_Btns button h3 {
  position: absolute;
  top: 15px;
  left: 15px;
  color: white;
  font-family: vektaGroupB;
}
.databaseCenter .databaseOptions_Btns button:hover {
  box-shadow: 0px 0px 15px 0px #bdbdbd;
}
.databaseCenter .databaseOptions_Btns button:hover .still {
  opacity: 0;
}
.databaseCenter .databaseOptions_Btns button:hover .moving {
  filter: saturate(0.5);
}
.databaseCenter .turbineDB_Content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  margin-top: 30px;
  height: 90%;
  color: black;
}
.databaseCenter .turbineDB_Content hr {
  border-top: 3px solid rgb(159, 238, 214);
  width: 100%;
  margin: 0;
}
.databaseCenter .turbineDB_Content .databaseNav {
  position: absolute;
  top: -20px;
  left: 25px;
}
.databaseCenter .turbineDB_Content .turbineList,
.databaseCenter .turbineDB_Content .turbineListP {
  width: 46%;
  height: 100%;
  padding: 10px;
  box-shadow: 0px 0px 15px 0px #bdbdbd;
  border-radius: 8px;
}
.databaseCenter .turbineDB_Content .turbineList .turbineList_heading,
.databaseCenter .turbineDB_Content .turbineList .turbineListP_heading,
.databaseCenter .turbineDB_Content .turbineListP .turbineList_heading,
.databaseCenter .turbineDB_Content .turbineListP .turbineListP_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.databaseCenter .turbineDB_Content .turbineList .turbineList_heading .input,
.databaseCenter .turbineDB_Content .turbineList .turbineListP_heading .input,
.databaseCenter .turbineDB_Content .turbineListP .turbineList_heading .input,
.databaseCenter .turbineDB_Content .turbineListP .turbineListP_heading .input {
  display: flex;
}
.databaseCenter .turbineDB_Content .turbineList .turbineList_heading .input input,
.databaseCenter .turbineDB_Content .turbineList .turbineList_heading .input select,
.databaseCenter .turbineDB_Content .turbineList .turbineListP_heading .input input,
.databaseCenter .turbineDB_Content .turbineList .turbineListP_heading .input select,
.databaseCenter .turbineDB_Content .turbineListP .turbineList_heading .input input,
.databaseCenter .turbineDB_Content .turbineListP .turbineList_heading .input select,
.databaseCenter .turbineDB_Content .turbineListP .turbineListP_heading .input input,
.databaseCenter .turbineDB_Content .turbineListP .turbineListP_heading .input select {
  width: calc(230px / var(--scaleFactor));
  height: 40px !important;
  border-radius: 8px;
  border: none;
}
.databaseCenter .turbineDB_Content .turbineList .turbineList_heading .basicSearch,
.databaseCenter .turbineDB_Content .turbineList .turbineListP_heading .basicSearch,
.databaseCenter .turbineDB_Content .turbineListP .turbineList_heading .basicSearch,
.databaseCenter .turbineDB_Content .turbineListP .turbineListP_heading .basicSearch {
  width: 40%;
  display: flex;
  justify-content: space-between;
}
.databaseCenter .turbineDB_Content .turbineList .turbineList_heading .basicSearch .input input,
.databaseCenter .turbineDB_Content .turbineList .turbineListP_heading .basicSearch .input input,
.databaseCenter .turbineDB_Content .turbineListP .turbineList_heading .basicSearch .input input,
.databaseCenter .turbineDB_Content .turbineListP .turbineListP_heading .basicSearch .input input {
  background-color: rgba(128, 128, 128, 0.0784313725);
  border-radius: 8px 0px 0px 8px;
}
.databaseCenter .turbineDB_Content .turbineList .turbineList_heading .basicSearch .input button,
.databaseCenter .turbineDB_Content .turbineList .turbineListP_heading .basicSearch .input button,
.databaseCenter .turbineDB_Content .turbineListP .turbineList_heading .basicSearch .input button,
.databaseCenter .turbineDB_Content .turbineListP .turbineListP_heading .basicSearch .input button {
  background-color: rgba(128, 128, 128, 0.0784313725);
  border: none;
  border-radius: 0px 8px 8px 0px;
}
.databaseCenter .turbineDB_Content .turbineList .turb_Group,
.databaseCenter .turbineDB_Content .turbineListP .turb_Group {
  height: 92%;
  overflow: scroll;
}
.databaseCenter .turbineDB_Content .turbineList .turbineList_search,
.databaseCenter .turbineDB_Content .turbineListP .turbineList_search {
  display: none;
  justify-content: center;
  background-color: #007592;
  padding: 5px;
  border-radius: 8px;
}
.databaseCenter .turbineDB_Content .turbineList .turbineList_search .filterRating,
.databaseCenter .turbineDB_Content .turbineListP .turbineList_search .filterRating {
  width: 30%;
}
.databaseCenter .turbineDB_Content .turbineList .turbineList_search .filterGroup,
.databaseCenter .turbineDB_Content .turbineListP .turbineList_search .filterGroup {
  display: flex;
  flex-direction: column;
}
.databaseCenter .turbineDB_Content .turbineList .turbineList_search .filterGroup .filterManufacturer .form-group,
.databaseCenter .turbineDB_Content .turbineListP .turbineList_search .filterGroup .filterManufacturer .form-group {
  width: 100%;
  margin: 0;
}
.databaseCenter .turbineDB_Content .turbineList .turbineList_search .filterGroup .twoOptionFilters,
.databaseCenter .turbineDB_Content .turbineListP .turbineList_search .filterGroup .twoOptionFilters {
  display: flex;
  justify-content: space-between;
}
.databaseCenter .turbineDB_Content .turbineList .toggle_filters,
.databaseCenter .turbineDB_Content .turbineListP .toggle_filters {
  display: flex;
}
.databaseCenter .turbineDB_Content .turbineList .turbinesFromDB,
.databaseCenter .turbineDB_Content .turbineList .turbinesFromP,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromDB,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromP {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: scroll;
}
.databaseCenter .turbineDB_Content .turbineList .turbinesFromDB .turb_card,
.databaseCenter .turbineDB_Content .turbineList .turbinesFromP .turb_card,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromDB .turb_card,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromP .turb_card {
  position: relative;
}
.databaseCenter .turbineDB_Content .turbineList .turbinesFromDB .turb_card .turbineDB,
.databaseCenter .turbineDB_Content .turbineList .turbinesFromP .turb_card .turbineDB,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromDB .turb_card .turbineDB,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromP .turb_card .turbineDB {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: calc(240px / var(--scaleFactor));
  height: calc(200px / var(--scaleFactor));
  padding: calc(15px / var(--scaleFactor));
  margin: calc(10px / var(--scaleFactor));
  background: none;
  border: 3px solid #007592;
  border-radius: 8px;
}
.databaseCenter .turbineDB_Content .turbineList .turbinesFromDB .turb_card .turbineDB h4,
.databaseCenter .turbineDB_Content .turbineList .turbinesFromP .turb_card .turbineDB h4,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromDB .turb_card .turbineDB h4,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromP .turb_card .turbineDB h4 {
  text-transform: uppercase;
}
.databaseCenter .turbineDB_Content .turbineList .turbinesFromDB .turb_card .turbineDB .manRate,
.databaseCenter .turbineDB_Content .turbineList .turbinesFromP .turb_card .turbineDB .manRate,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromDB .turb_card .turbineDB .manRate,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromP .turb_card .turbineDB .manRate {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}
.databaseCenter .turbineDB_Content .turbineList .turbinesFromDB .turb_card .turbineDB .manRate .man,
.databaseCenter .turbineDB_Content .turbineList .turbinesFromDB .turb_card .turbineDB .manRate .rate,
.databaseCenter .turbineDB_Content .turbineList .turbinesFromP .turb_card .turbineDB .manRate .man,
.databaseCenter .turbineDB_Content .turbineList .turbinesFromP .turb_card .turbineDB .manRate .rate,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromDB .turb_card .turbineDB .manRate .man,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromDB .turb_card .turbineDB .manRate .rate,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromP .turb_card .turbineDB .manRate .man,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromP .turb_card .turbineDB .manRate .rate {
  display: flex;
  flex-direction: column;
  position: relative;
}
.databaseCenter .turbineDB_Content .turbineList .turbinesFromDB .turb_card .turbineDB .manRate .man label,
.databaseCenter .turbineDB_Content .turbineList .turbinesFromDB .turb_card .turbineDB .manRate .rate label,
.databaseCenter .turbineDB_Content .turbineList .turbinesFromP .turb_card .turbineDB .manRate .man label,
.databaseCenter .turbineDB_Content .turbineList .turbinesFromP .turb_card .turbineDB .manRate .rate label,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromDB .turb_card .turbineDB .manRate .man label,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromDB .turb_card .turbineDB .manRate .rate label,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromP .turb_card .turbineDB .manRate .man label,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromP .turb_card .turbineDB .manRate .rate label {
  margin: 0;
  position: absolute;
  top: -15px;
  color: #9feed6;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 700;
}
.databaseCenter .turbineDB_Content .turbineList .turbinesFromDB .turb_card .turbineDB .manRate .man h5,
.databaseCenter .turbineDB_Content .turbineList .turbinesFromDB .turb_card .turbineDB .manRate .rate h5,
.databaseCenter .turbineDB_Content .turbineList .turbinesFromP .turb_card .turbineDB .manRate .man h5,
.databaseCenter .turbineDB_Content .turbineList .turbinesFromP .turb_card .turbineDB .manRate .rate h5,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromDB .turb_card .turbineDB .manRate .man h5,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromDB .turb_card .turbineDB .manRate .rate h5,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromP .turb_card .turbineDB .manRate .man h5,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromP .turb_card .turbineDB .manRate .rate h5 {
  width: 100%;
  padding: 10px;
  margin: 0;
  background-color: #9feed6;
  border-radius: 8px;
  text-transform: capitalize;
}
.databaseCenter .turbineDB_Content .turbineList .turbinesFromDB .turb_card .turbineDB .manRate .man,
.databaseCenter .turbineDB_Content .turbineList .turbinesFromP .turb_card .turbineDB .manRate .man,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromDB .turb_card .turbineDB .manRate .man,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromP .turb_card .turbineDB .manRate .man {
  width: 100%;
}
.databaseCenter .turbineDB_Content .turbineList .turbinesFromDB .turb_card .turbineDB .manRate .rate,
.databaseCenter .turbineDB_Content .turbineList .turbinesFromP .turb_card .turbineDB .manRate .rate,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromDB .turb_card .turbineDB .manRate .rate,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromP .turb_card .turbineDB .manRate .rate {
  margin-top: calc(20px / var(--scaleFactor));
  width: 35%;
}
.databaseCenter .turbineDB_Content .turbineList .turbinesFromDB .turb_card .turbineDB span,
.databaseCenter .turbineDB_Content .turbineList .turbinesFromP .turb_card .turbineDB span,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromDB .turb_card .turbineDB span,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromP .turb_card .turbineDB span {
  display: none;
  position: absolute;
  width: 120px;
  fill-opacity: 0.6;
}
.databaseCenter .turbineDB_Content .turbineList .turbinesFromDB .turb_card .turb_interrogate,
.databaseCenter .turbineDB_Content .turbineList .turbinesFromP .turb_card .turb_interrogate,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromDB .turb_card .turb_interrogate,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromP .turb_card .turb_interrogate {
  position: absolute;
  display: none;
  left: 10px;
  top: calc(80px / var(--scaleFactor));
  width: 92%;
  align-items: center;
  justify-content: space-around;
}
.databaseCenter .turbineDB_Content .turbineList .turbinesFromDB .turb_card .turb_interrogate button,
.databaseCenter .turbineDB_Content .turbineList .turbinesFromP .turb_card .turb_interrogate button,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromDB .turb_card .turb_interrogate button,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromP .turb_card .turb_interrogate button {
  width: calc(60px / var(--scaleFactor));
  height: calc(60px / var(--scaleFactor));
}
.databaseCenter .turbineDB_Content .turbineList .turbinesFromDB .turb_card:hover .turbineDB,
.databaseCenter .turbineDB_Content .turbineList .turbinesFromP .turb_card:hover .turbineDB,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromDB .turb_card:hover .turbineDB,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromP .turb_card:hover .turbineDB {
  filter: blur(1px);
}
.databaseCenter .turbineDB_Content .turbineList .turbinesFromDB .turb_card:hover .turb_interrogate,
.databaseCenter .turbineDB_Content .turbineList .turbinesFromP .turb_card:hover .turb_interrogate,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromDB .turb_card:hover .turb_interrogate,
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromP .turb_card:hover .turb_interrogate {
  display: flex;
}
.databaseCenter .turbineDB_Content .turbineListP {
  width: 45%;
  padding: 10px;
  box-shadow: 0px 0px 15px 0px #bdbdbd;
  border-radius: 8px;
}
.databaseCenter .turbineDB_Content .turbineListP .turbineListP_heading {
  display: flex;
  justify-content: space-between;
}
.databaseCenter .turbineDB_Content .turbineListP .turbinesFromP {
  height: 92%;
}
.databaseCenter .turbineDB_Content .turbineDB_Btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 8px;
  padding: 10px;
}
.databaseCenter .turbineDB_Content .turbineDB_Btn:hover {
  background: #c5c5c5;
}

.customTurbine {
  width: 90%;
  left: 5%;
}
.customTurbine hr {
  border-top: 3px solid rgb(159, 238, 214);
  width: 100%;
  margin-top: 0;
}
.customTurbine .popup_body {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.customTurbine .customTurb_mainInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}
.customTurbine .customTurb_mainInfo .inputGroup {
  display: flex;
  justify-content: space-between;
}
.customTurbine .customTurb_mainInfo .inputGroup .input {
  width: 49%;
}
.customTurbine .customTurb_mainInfo .groupedInputs {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #007592;
  border-radius: 8px 8px 0px 0px;
  color: white;
}
.customTurbine .customTurb_mainInfo .groupedInputs .input input {
  text-transform: capitalize;
  color: black;
  width: 100%;
  height: 45px !important;
  border-radius: 8px;
  border: none;
}
.customTurbine .customTurb_mainInfo .groupedInputs .turb_settings {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  /* The switch - the box around the slider */
}
.customTurbine .customTurb_mainInfo .groupedInputs .turb_settings .input {
  display: flex;
}
.customTurbine .customTurb_mainInfo .groupedInputs .turb_settings .input label {
  display: flex;
  flex-direction: column;
  width: 53px;
}
.customTurbine .customTurb_mainInfo .groupedInputs .turb_settings .input label img {
  width: 35px;
  height: 60px;
  filter: drop-shadow(2px 4px 6px black);
}
.customTurbine .customTurb_mainInfo .groupedInputs .turb_settings .input span {
  margin-left: -50px;
  padding-left: 10px;
  width: 50px;
  height: 40px;
  color: #00A0C6;
}
.customTurbine .customTurb_mainInfo .groupedInputs .turb_settings .input span h4 {
  width: 50px;
}
.customTurbine .customTurb_mainInfo .groupedInputs .turb_settings .input input {
  width: 70%;
}
.customTurbine .customTurb_mainInfo .groupedInputs .turb_settings label {
  margin-bottom: 0;
}
.customTurbine .customTurb_mainInfo .groupedInputs .turb_settings .toggleSwitch {
  display: flex;
  align-items: center;
  /* The slider */
  /* Rounded sliders */
}
.customTurbine .customTurb_mainInfo .groupedInputs .turb_settings .toggleSwitch .choice {
  text-transform: uppercase;
}
.customTurbine .customTurb_mainInfo .groupedInputs .turb_settings .toggleSwitch .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 10px;
}
.customTurbine .customTurb_mainInfo .groupedInputs .turb_settings .toggleSwitch .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.customTurbine .customTurb_mainInfo .groupedInputs .turb_settings .toggleSwitch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  transition: 0.4s;
}
.customTurbine .customTurb_mainInfo .groupedInputs .turb_settings .toggleSwitch .slider::before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #9feed6;
  transition: 0.4s;
}
.customTurbine .customTurb_mainInfo .groupedInputs .turb_settings .toggleSwitch input:checked + .slider {
  background-color: white;
}
.customTurbine .customTurb_mainInfo .groupedInputs .turb_settings .toggleSwitch input:focus + .slider {
  box-shadow: 0 0 1px white;
}
.customTurbine .customTurb_mainInfo .groupedInputs .turb_settings .toggleSwitch input:checked + .slider:before {
  transform: translateX(26px);
}
.customTurbine .customTurb_mainInfo .groupedInputs .turb_settings .toggleSwitch .slider.round {
  border-radius: 34px;
}
.customTurbine .customTurb_mainInfo .groupedInputs .turb_settings .toggleSwitch .slider.round:before {
  border-radius: 50%;
}
.customTurbine .customTurb_mainInfo .turbTransformers {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 5px solid #007592;
  border-radius: 0px 0px 8px 8px;
  border-top: none;
}
.customTurbine .customTurb_mainInfo .turbTransformers h4 {
  text-transform: uppercase;
}
.customTurbine .customTurb_mainInfo .turbTransformers .input {
  margin-bottom: 5px;
}
.customTurbine .customTurb_mainInfo .turbTransformers .input input {
  width: 100%;
  height: 45px !important;
  background: rgba(185, 185, 185, 0.1490196078);
  border-radius: 8px;
  border: none;
}
.customTurbine .customTurb_mainInfo .turbTransformers .transformerClasses {
  display: flex;
  justify-content: space-around;
}
.customTurbine .customTurb_mainInfo .turbTransformers .transformerClasses .transformerStandard,
.customTurbine .customTurb_mainInfo .turbTransformers .transformerClasses .transformerEco {
  width: 49%;
}
.customTurbine .customTurb_mainInfo .turbCost {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 5px solid #007592;
}
.customTurbine .customTurb_mainInfo .turbCost h4 {
  text-transform: uppercase;
}
.customTurbine .customTurb_mainInfo .turbCost .input {
  margin-bottom: 5px;
}
.customTurbine .customTurb_mainInfo .turbCost .input input {
  width: 100%;
  height: 45px !important;
  background: rgba(185, 185, 185, 0.1490196078);
  border-radius: 8px;
  border: none;
}
.customTurbine .customTurb_subInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.customTurbine .customTurb_subInfo .wtg_powerCurve {
  position: relative;
}
.customTurbine .customTurb_subInfo .wtg_powerCurve button {
  position: absolute;
  right: 25px;
  top: 60px;
  padding: 10px;
  border: none;
  border-radius: 8px;
}
.customTurbine .customTurb_subInfo .wtg_powerCurve button:hover {
  background: #c5c5c5;
}
.customTurbine .customTurb_subInfo .wtg_powerCurve .powercurveTable {
  display: none;
  position: absolute;
  top: 96px;
  right: 25px;
  height: 400px;
  padding: 10px;
  border-radius: 8px;
  background-color: buttonface;
}
.customTurbine .customTurb_subInfo .wtg_powerCurve .toggle_powercurveTable {
  display: block;
}
.customTurbine .customTurb_subInfo .turbDimesions {
  position: relative;
  margin-right: 60px;
}
.customTurbine .customTurb_subInfo .turbDimesions .input {
  position: absolute;
  margin-bottom: 5px;
  border-radius: 8px;
  background-color: #007592;
  padding: 10px;
}
.customTurbine .customTurb_subInfo .turbDimesions .input label {
  color: white;
}
.customTurbine .customTurb_subInfo .turbDimesions .input input,
.customTurbine .customTurb_subInfo .turbDimesions .input select {
  height: 45px !important;
  border-radius: 8px;
  border: none;
  width: 100%;
}
.customTurbine .customTurb_subInfo .turbDimesions .hubHeight {
  right: -170px;
  bottom: 30px;
  width: 37%;
}
.customTurbine .customTurb_subInfo .turbDimesions .diameter {
  left: 50px;
  top: 144px;
  width: 34%;
}
.customTurbine .customTurb_subInfo .turbDimesions .sweptArea {
  bottom: 19px;
  left: -11px;
  width: 43%;
}
.customTurbine .customTurb_subInfo .turbDimesions .powerDensity {
  right: -170px;
  width: 36%;
}

.toggle_mainPane {
  display: block;
}

.vektaStore {
  font-family: "Montserrat" !important;
}
.vektaStore .vektaStore_tool {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f5f5f5;
  box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.3215686275);
  padding: 16px;
  width: 350px;
  height: 250px;
  border-radius: 8px;
}
.vektaStore .vektaStore_tool .toolPricing {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 32px;
}
.vektaStore .vektaStore_tool .toolPricing p {
  margin-left: 8px;
}
.vektaStore .vektaStore_tool .toolPricing .productInstall {
  border-radius: 50px;
  width: 85%;
  background-color: #00A0C6;
  border: none;
  color: white;
  padding: 16px;
}
.vektaStore .vektaStore_tool .toolPricing .productInstall:hover {
  scale: 1.05;
}
.vektaStore .vektaStore_tool .ownedInfo {
  font-weight: 600;
}
.vektaStore.toggle_group {
  display: flex;
  flex-direction: column;
}
.vektaStore .toolDisplay {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  height: 100%;
  overflow-y: scroll;
  padding: 8px;
}

.userProfile .sidePanel_heading {
  display: flex;
  justify-content: center;
}
.userProfile .sidePanel_heading h3 {
  margin-top: 0;
}
.userProfile .sidePanel_heading .vektaStoreProfile {
  width: 50px;
}
.userProfile .sidePanel_heading .vektaStoreProfile svg {
  font-size: xx-large;
}
.userProfile .sidePanel_heading .popup_close {
  position: absolute;
  right: 40px;
  padding: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}
.userProfile .sidePanel_heading .popup_close:hover {
  background-color: rgb(255, 0, 0);
  color: rgb(255, 255, 255);
}
.userProfile .sidePanel_body .yourInfo_heading {
  display: flex;
  justify-content: space-between;
}

.installTool .popup_heading {
  display: flex;
  justify-content: space-between;
}
.installTool .popup_heading h3 {
  margin-top: 0;
}
.installTool .popup_heading .popup_close {
  float: right;
  padding: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}
.installTool .popup_heading .popup_close:hover {
  background-color: rgb(255, 0, 0);
  color: rgb(255, 255, 255);
}
.installTool .purchaseSelect {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 75%;
  padding: 5px;
  height: 6rem;
  margin-bottom: 16px;
  background-color: #007592;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.2rem;
  font-weight: 600;
}
.installTool .purchaseSelect:hover {
  background-color: #007592;
  box-shadow: inset 7px 8px 8px 0px #005b72;
}

.productKey_entry .popup_body {
  margin-top: 24px;
}
.productKey_entry .p-float-label input.p-filled ~ label {
  top: -1rem !important;
  color: black;
}
.productKey_entry .p-inputtext:enabled:focus {
  box-shadow: none;
}
.productKey_entry .p-float-label input:focus ~ label,
.productKey_entry .p-float-label .p-inputwrapper-filled ~ label,
.productKey_entry .p-float-label .p-inputwrapper-focus ~ label {
  font-size: 16px;
  color: black;
  top: -1.5rem;
}

.mapOutput_popup {
  height: 95%;
}
.mapOutput_popup .popup_heading {
  display: flex;
  justify-content: space-between;
}
.mapOutput_popup .popup_heading h3 {
  margin-top: 0;
}
.mapOutput_popup .popup_heading .popup_close {
  float: right;
  padding: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}
.mapOutput_popup .popup_heading .popup_close:hover {
  background-color: rgb(255, 0, 0);
  color: rgb(255, 255, 255);
}
.mapOutput_popup .downloadMap_button {
  position: absolute;
  right: 20px;
  bottom: 20px;
  padding: 8px;
  cursor: pointer;
  outline: none;
}
.mapOutput_popup .mapContainer .mainMap .northArrow {
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  z-index: 401;
}
.mapOutput_popup .mapContainer .mainMap .northArrow.topRight {
  top: 16px;
  right: 16px;
}
.mapOutput_popup .mapContainer .mainMap .northArrow.topLeft {
  top: 16px;
  left: 16px;
}
.mapOutput_popup .mapContainer .mainMap .northArrow.bottomRight {
  bottom: 16px;
  right: 16px;
}
.mapOutput_popup .mapContainer .mainMap .northArrow.bottomLeft {
  bottom: 16px;
  left: 16px;
}
.mapOutput_popup .mapContainer .sideContent .miniMap {
  width: 100%;
  height: 23%;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mapOutput_popup .mapContainer .sideContent .legendControl {
  height: 45%;
  border: 1px solid;
}
.mapOutput_popup .mapContainer .sideContent .legendControl .legend_layoutTree .jstree-clicked {
  background: #007592 !important;
}
.mapOutput_popup .mapContainer .sideContent .legendControl .legend_layoutTree .jstree-hovered {
  background: #00A0C6 !important;
}
.mapOutput_popup .mapContainer .sideContent .legendControl .legend_layoutTree .jstree-rename-input {
  color: black;
}
.mapOutput_popup .mapContainer .sideContent .legendControl .legend_layoutTree .jstree-default a {
  white-space: normal !important;
  height: auto;
}
.mapOutput_popup .mapContainer .sideContent .legendControl .legend_layoutTree .jstree-anchor {
  height: auto !important;
}
.mapOutput_popup .mapContainer .sideContent .legendControl .legend_layoutTree .jstree-default li > ins {
  vertical-align: top;
}
.mapOutput_popup .mapContainer .sideContent .legendControl .legend_layoutTree .jstree-leaf {
  height: auto;
}
.mapOutput_popup .mapContainer .sideContent .legendControl .legend_layoutTree .jstree-leaf a {
  height: auto !important;
  white-space: initial;
  max-width: 90%;
}
.mapOutput_popup .mapContainer .sideContent .legendControl .legend_layoutTree .jstree-disabled > .jstree-icon {
  opacity: 1;
  filter: none;
}
.mapOutput_popup .mapContainer .sideContent .metaData {
  border: 1px solid;
  padding: 8px;
}
.mapOutput_popup .mapContainer .sideContent .metaData .metaData_options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.mapOutput_popup .mapContainer .sideContent .metaData .metaData_options p {
  width: 50%;
  font-size: smaller;
}
.mapOutput_popup .mapContainer .sideContent .metaData_extra {
  width: 100%;
  height: 10%;
  border: 1px solid;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.mapOutput_popup .mapContainer .sideContent .metaData_extra .scaleBar {
  width: 35%;
}
.mapOutput_popup .mapContainer .sideContent .metaData_extra .logo {
  width: 25%;
}
.mapOutput_popup .mapContainer .sideContent .metaData_extra .logo img {
  width: 100%;
}
.mapOutput_popup .settingsContainer .input {
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
}
.mapOutput_popup .settingsContainer .input input,
.mapOutput_popup .settingsContainer .input select {
  height: 42pt !important;
  border-radius: 8px;
  padding: 10px;
  width: 160pt;
  border: 2px solid #007592;
}
.mapOutput_popup .settingsContainer .input .toggle_missingInfoShake {
  border: 2px solid red;
  animation: shakeError 0.2s ease-in-out;
}
@keyframes shakeError {
  0% {
    margin-left: 0rem;
  }
  25% {
    margin-left: 0.5rem;
  }
  75% {
    margin-left: -0.5rem;
  }
  100% {
    margin-left: 0rem;
  }
}
.mapOutput_popup .settingsContainer .input .toggle_missingInfo {
  border: 2px solid red;
}
.mapOutput_popup .settingsContainer .coords_options {
  display: flex;
  justify-content: space-evenly;
}
.mapOutput_popup .settingsContainer .metaData_settingInputs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.mapOutput_popup .settingsContainer .settings_section {
  width: 100%;
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 8px;
}
.mapOutput_popup .settingsContainer .settings_section .mapOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  background: #00A0C6;
  padding: 8px;
  border-radius: 6px;
}
.mapOutput_popup .settingsContainer .settings_section .mapOption h4 {
  margin: 0;
  color: white;
}
.mapOutput_popup .settingsContainer .settings_section .mapOption input[type=checkbox] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  outline: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: #00A0C6;
  width: 2.5rem;
  height: 2.5rem;
  border: 0.15em solid #00A0C6;
  border-radius: 4px;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}
.mapOutput_popup .settingsContainer .settings_section .mapOption input[type=checkbox]::before {
  content: "";
  width: 0.7em;
  height: 0.7em;
  transform: scale(0);
  transform-origin: bottom left;
  -webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
          clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #00A0C6;
  border-radius: 3px;
}
.mapOutput_popup .settingsContainer .settings_section .mapOption input[type=checkbox]:checked::before {
  transform: scale(1);
}
.mapOutput_popup .settingsContainer .settings_section .mapOption label {
  margin: 0 8px 0 0;
  color: white;
}
.mapOutput_popup .settingsContainer .grid_settings .grid_options .grid_input {
  display: flex;
  justify-content: space-between;
}
.mapOutput_popup .settingsContainer .grid_settings .grid_options .grid_input .input {
  width: 80%;
}
.mapOutput_popup .settingsContainer .grid_settings .grid_options .grid_input input {
  width: 15%;
  background-color: #e9e9e9;
  border-radius: 8px;
  border: none;
  padding: 8px;
}

.cn-wrapper {
  font-size: 1em;
  width: 26em;
  height: 26em;
  overflow: hidden;
  position: fixed;
  z-index: 10;
  left: 50%;
  border-radius: 50%;
  margin-left: -13em;
  opacity: 0;
  border: 1px solid lightgray;
  background-color: white;
  transition: all 0.3s ease-in-out;
}
.cn-wrapper.opened-nav {
  border-radius: 50%;
  opacity: 1;
  margin-left: -15.5em;
}
.cn-wrapper .first-controls li {
  border: 1px solid lightgray;
  position: absolute;
  font-size: 1.5em;
  z-index: inherit;
  width: 10em;
  height: 10em;
  transform-origin: 100% 100%;
  overflow: hidden;
  left: 50%;
  margin-top: -1.3em;
  margin-left: -10em;
  transition: border 0.3s ease;
}
.cn-wrapper .first-controls li a {
  display: block;
  font-size: 1.18em;
  height: 14.5em;
  width: 14.5em;
  position: absolute;
  bottom: -7.25em;
  right: -7.25em;
  border-radius: 50%;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.54);
  padding-top: 1.8em;
  text-align: center;
  transform: skew(-60deg) rotate(-70deg) scale(1);
  transition: background-color 0.2s ease, transform 0.2s ease;
  padding-right: 12px;
}
.cn-wrapper .first-controls li a svg {
  font-size: 1em;
  color: rgba(0, 0, 0, 0.54);
}
.cn-wrapper .first-controls li a:hover {
  background-color: #f5f5f5 !important;
}
.cn-wrapper .first-controls li a:active,
.cn-wrapper .first-controls li a :focus {
  background-color: #e0e0e0;
}
.cn-wrapper .first-controls li:first-child {
  transform: rotate(-90deg) skew(60deg);
}
.cn-wrapper .first-controls li:first-child a svg {
  transform: rotate(160deg);
}
.cn-wrapper .first-controls li:nth-child(2) {
  transform: rotate(-60deg) skew(60deg);
}
.cn-wrapper .first-controls li:nth-child(2) a svg {
  transform: rotate(130deg);
}
.cn-wrapper .first-controls li:nth-child(3) {
  transform: rotate(-30deg) skew(60deg);
}
.cn-wrapper .first-controls li:nth-child(3) a svg {
  transform: rotate(100deg);
}
.cn-wrapper .first-controls li:nth-child(4) {
  transform: rotate(0deg) skew(60deg);
}
.cn-wrapper .first-controls li:nth-child(4) a svg {
  transform: rotate(70deg);
}
.cn-wrapper .first-controls li:nth-child(5) {
  transform: rotate(30deg) skew(60deg);
}
.cn-wrapper .first-controls li:nth-child(5) a svg {
  transform: rotate(35deg);
}
.cn-wrapper .first-controls li:nth-child(6) {
  transform: rotate(60deg) skew(60deg);
}

* {
  box-sizing: border-box;
}

.rag-red {
  background-color: rgba(204, 34, 34, 0.7607843137);
}

.rag-green {
  background-color: rgba(51, 204, 51, 0.7529411765);
}

.rag-orange {
  background-color: rgba(204, 130, 34, 0.7725490196);
}

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat";
  overflow: hidden !important;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
body ::-webkit-scrollbar {
  width: 10px;
}
body ::-webkit-scrollbar-track {
  background: transparent;
}
body ::-webkit-scrollbar-thumb {
  background: #00A0C6;
  border-radius: 8px;
  height: 50px;
}
body ::-webkit-scrollbar-thumb:hover {
  background: #555;
}
body .ReactModal__Overlay {
  z-index: 1000;
  background-color: rgba(181, 181, 181, 0.75) !important;
}

h2 {
  text-transform: uppercase;
  margin: 0;
  font-family: "Montserrat";
}

label {
  margin-bottom: 10px;
}

.container-fluid {
  padding: 0;
}

.vl {
  border-left: 3px solid #007592;
  height: 30px;
  margin: 0 3px;
}

.new_popup input,
.new_popup select,
.existing_popup input,
.existing_popup select {
  height: 30px;
  width: 100%;
  margin-bottom: 10px;
  outline: none;
  border: none;
  color: rgb(0, 0, 0);
}

.airdatepickers-container .airdatepicker {
  max-width: 230px;
}

.input-group {
  display: flex;
}

.shiny-progress-container {
  top: 55%;
}
.shiny-progress-container .progress {
  left: 25%;
  width: 50vw;
  height: 10px;
}
.shiny-progress-container .progress-text {
  left: 25%;
  top: -30px;
  display: flex;
  align-items: center;
  width: 300px;
  background: none;
  color: white;
}

.overlay,
.gis_in_overlay {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  background: #999;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  z-index: 1001;
}

.saveLoadShareBanner {
  width: 100%;
  opacity: 0;
  position: absolute;
  z-index: 2001;
  text-align: center;
  background-color: lightgreen;
  color: white;
}

.toggle_saveLoad {
  animation-name: inout;
  animation-duration: 4s;
}

@keyframes inout {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
a.polyline-measure-controlOnBgColor,
a.polyline-measure-controlOnBgColor:hover {
  background-color: #8f8;
}

.polyline-measure-unicode-icon {
  font-size: 24px;
  font-weight: bold;
}

.polyline-measure-tooltip {
  font: 12px Arial, Helvetica, sans-serif;
  line-height: 12px;
  background-color: #00A0C6;
  color: white;
  border-radius: 3px;
  box-shadow: 1px 1px 4px #888;
  margin: 0;
  padding: 2px;
  width: auto !important;
  height: auto !important;
  white-space: nowrap;
  text-align: right;
}

.polyline-measure-tooltip-total {
  color: white;
  font-size: 12px;
  font-weight: bold;
}

.polyline-measure-tooltip-difference {
  color: white;
  font-size: 12px;
  font-style: italic;
}

.measureTool svg {
  fill: rgba(0, 0, 0, 0.54);
  border-radius: 50%;
  padding: 5px;
  height: 100%;
}

.leaflet-control-container .leaflet-control-attribution {
  font-size: x-small;
}
.leaflet-control-container .leaflet-control-zoom {
  display: none;
}
.leaflet-control-container .leaflet-bar {
  width: 48px;
}
.leaflet-control-container .leaflet-bar a {
  width: 44px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}
.leaflet-control-container .leaflet-bar a#unitControlId {
  font-size: 12px;
  border-top: 3px solid #c3c3c3;
}
.leaflet-control-container .leaflet-bar a#unitControlId:hover {
  background-color: #f5f5f5;
}
.leaflet-control-container .leaflet-bar a:hover {
  background-color: white;
}
.leaflet-control-container .leaflet-bar a:hover svg {
  background-color: #dfdfdf;
}
.leaflet-control-container .leaflet-bar .polyline-measure-controlOnBgColor svg {
  background-color: #00A0C6;
}
.leaflet-control-container .leaflet-bar .polyline-measure-controlOnBgColor:hover svg {
  fill: white;
  background-color: #007592;
}
.leaflet-control-container .leafet_legendContaier {
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 8px;
}
.leaflet-control-container .leafet_legendContaier h5 {
  text-align: center;
}
.leaflet-control-container .leafet_legendContaier .powerlineosm,
.leaflet-control-container .leafet_legendContaier .powerplantonshoreosm,
.leaflet-control-container .leafet_legendContaier .powerplantpolygonosm {
  width: 140px;
}
.leaflet-control-container .leafet_legendContaier .legend_close {
  position: fixed;
  bottom: 25px;
  right: 20px;
  width: 35px;
  float: right;
  border: none;
  font-size: medium;
  padding: 5px;
  border-radius: 8px;
  background-color: rgb(209, 209, 209);
}
.leaflet-control-container .leafet_legendContaier .legend_close:hover {
  color: white;
  background-color: lightcoral;
}
.leaflet-control-container .leafet_legendContaier .leafet_legends {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}
.leaflet-control-container .leafet_legendContaier .leafet_legends img {
  max-width: 100%;
}
.leaflet-control-container .leafet_legendContaier .legend_minimise {
  width: 100%;
  height: 100%;
  border: none;
  background: none;
}
.leaflet-control-container .leafet_legendContaier .legend_minimise span {
  font-size: medium;
}

.legendContainer,
.coordMarker {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.legendContainer button,
.coordMarker button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.legendContainer svg,
.coordMarker svg {
  width: 20px;
}
.legendContainer .activeLegends,
.coordMarker .activeLegends {
  display: none;
}
.legendContainer .activeLegends_close,
.coordMarker .activeLegends_close {
  display: none;
}
.legendContainer:hover,
.coordMarker:hover {
  cursor: pointer;
}

.legendContainer_toggle {
  width: 245px;
  height: 450px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
}
.legendContainer_toggle button svg {
  display: none;
}
.legendContainer_toggle .activeLegends {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 240px;
  position: absolute;
  left: 0;
  top: 0;
}
.legendContainer_toggle .activeLegends .activeLegend_container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: white;
  width: 100%;
}
.legendContainer_toggle .activeLegends .activeLegend_container img {
  width: -moz-fit-content;
  width: fit-content;
}
.legendContainer_toggle .activeLegends .activeLegend_container .Power_Line_,
.legendContainer_toggle .activeLegends .activeLegend_container .Power_Plant_Polygon_,
.legendContainer_toggle .activeLegends .activeLegend_container .Power_Plant_Onshore_ {
  width: 140px;
}
.legendContainer_toggle .activeLegends_close {
  display: block;
  position: absolute;
  right: 0;
  top: 5px;
  font-size: 15px;
  border: 2px solid #00A0C6;
  border-radius: 8px;
}
.legendContainer_toggle .activeLegends_close:hover {
  background-color: #f4f4f4;
  cursor: pointer;
}

.leaflet-container .siteBuilder_options .input, .leaflet-container .siteCables_options .input {
  width: 100%;
}
.leaflet-container .siteBuilder_options .input input,
.leaflet-container .siteBuilder_options .input select, .leaflet-container .siteCables_options .input input,
.leaflet-container .siteCables_options .input select {
  height: 45px !important;
  border-radius: 8px;
  width: 100%;
  font-size: 14px;
}
.leaflet-container .siteBuilder_options .input .toggle_missingInfoShake, .leaflet-container .siteCables_options .input .toggle_missingInfoShake {
  animation: shakeError 0.2s ease-in-out 0s 2;
}
@keyframes shakeError {
  0% {
    margin-left: 0rem;
  }
  25% {
    margin-left: 0.5rem;
  }
  75% {
    margin-left: -0.5rem;
  }
  100% {
    margin-left: 0rem;
  }
}
.leaflet-container .siteBuilder_options .input .toggle_missingInfo, .leaflet-container .siteCables_options .input .toggle_missingInfo {
  border: 2px solid red;
}
.leaflet-container .siteBuilder_infoPopup {
  font-size: 1.2rem;
}
.leaflet-container .siteBuilder_infoPopup hr {
  margin-top: 0;
}
.leaflet-container .siteBuilder_infoPopup h4 {
  margin-right: 10px;
  color: #00A0C6;
}

.coordMarker_toggle {
  background-color: #00A0C6 !important;
}
.coordMarker_toggle button {
  fill: white;
}

.grey-screen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 1001;
  /* Adjust the z-index to make sure the overlay is on top of other elements */
  opacity: 0.7;
  /* Adjust the opacity as needed */
}
.grey-screen-overlay .loggingout-text {
  font-family: "Montserrat";
  z-index: 1005;
  /* Adjust the z-index to make sure the overlay is on top of other elements */
  color: white;
  /* Adjust the text color as needed */
  font-size: 20px;
  /* Adjust the font size as needed */
  text-align: center;
  /* Center the text horizontally */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.LayerOptionsBoxStyle {
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 8px;
  position: fixed;
  bottom: 50px;
  left: 500px;
  padding: 20px;
  z-index: 900;
}

.LayerOptionsToggleButtonStyle {
  position: absolute;
  right: 10px;
  bottom: 8%;
  z-index: 9999;
  width: 48px;
  height: 48px;
  border: none;
  border-radius: 8px;
  background-color: #ffffff;
}

.LayerOptionsInsideBoxStyle {
  padding-top: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 10px;
}

.InputStyle {
  width: 40%;
  height: 60%;
  margin-top: 7px;
  border: none;
  border-radius: 8px;
  background: #f5f5f5;
  margin-left: 15px;
}/*# sourceMappingURL=GISindex.css.map */