.modal-popups {
  position: fixed;
  z-index: 1001;

  // display: flex;
  // flex-direction: column;

  background: #fff;
  padding: 16px;
  border-radius: 8px;

  overflow-y: scroll;
  transition: 0.5s ease-in-out;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);

  inset: 5rem;

  @include popup_heading;
  @include popup_footer;

  body {
    overflow-y: scroll;

    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .guidance {
    color: rgb(141, 141, 141);
    margin: 16px;
  }
}

// .split {
//   display: flex;
//   width: 100%;

//   .resizer {
//     width: 5px;
//     background-color: #9b9b9bc7;

//     &:hover {
//       cursor: col-resize;
//     }
//   }
// }

.gis_map {
  display: none;
  flex: 1 1 0%;
  width: 100%;
  position: relative;

  .snapshot_btn {
    position: absolute;
    right: calc(50px / 1);
    top: 1%;

    button {
      padding: 7px;
      width: 44px;
      height: 44px;

      background: #fff;

      border-radius: 5px;
      border: none;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);

      display: flex;
      justify-content: space-around;
      align-items: center;

      svg {
        width: calc(20px / var(--scaleFactor));
        height: calc(20px / var(--scaleFactor));
      }
    }
  }

  .toggle_freeDraw {
    width: 0;
    padding: 0;

    .drawTab {
      right: 0;
    }
  }

  .layerSearch {
    position: absolute;
    right: 50px;
    top: 0;
    z-index: 1;

    height: 40px;
    margin: 10px 10px 0 0;

    display: flex;
    align-items: center;

    .form-group {
      margin: 0;
    }

    button {
      display: none;

      height: 35px;
      margin-right: 5px;
      width: 35px;

      align-items: center;
      justify-content: center;

      background: none;
      border: 2px solid $vektaBlueDark;
      border-radius: 50%;

      svg {
        height: calc(20px / var(--scaleFactor));
        width: calc(20px / var(--scaleFactor));

        fill: $vektaBlueDark;
      }
    }

    select {
      height: 100%;
      border-radius: 6px;
    }

    .toggle_shown {
      display: flex;
    }
  }
}

.saveDrawnLayer {
  height: calc(760px / var(--scaleFactor));

  .input {
    margin-bottom: 5px;

    input,
    select {
      height: 45px !important;
      border-radius: 8px;
      width: 100%;
      border: none;

      background-color: #ededed;
    }
  }

  .save_layerSettings {
    display: flex;
    justify-content: space-between;
  }
}

.customLayerStyle {
  width: calc(800px / var(--scaleFactor));
  left: 32%;

  .previewSettings {
    display: flex;
    justify-content: space-between;

    width: 100%;
    margin: 10px 0;

    .stylePreview {
      height: 150px;
      width: 40%;

      border: 2px solid $vektaBlueDark;
      border-radius: 8px;

      display: flex;

      .layerShape {
        margin: auto;
      }

      .point {
        height: 50px;
        width: 50px;
        border-radius: 50%;
      }

      .rectangle {
        height: 50px;
        width: 100px;
      }

      .line {
        width: 100px;
      }
    }
  }

  .groupedSettings {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .styleSettings {
      border-radius: 8px;
      padding: 10px 0;

      .range {
        position: relative;
        padding: 15px;

        input {
          width: 295px;
          height: 4px;
          border-radius: 5px;
          background-color: $vektaBlueDark;
          outline: none;

          &::-webkit-slider-thumb {
            -webkit-appearance: none;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: $vektaBlueDark;
          }
        }

        span {
          position: absolute;
          top: 0;
          right: 0;
          margin-left: 14px;

          width: 50px;
          height: 30px;

          background-color: rgb(212, 212, 212);

          border-radius: 3px;
          text-align: center;
          line-height: 30px;
        }
      }
    }

    .labelStyle {
    }
  }

  .advancedStyling {
    display: flex;
    flex-direction: column;

    .advancedStyling_content {
      display: none;
    }

    .toggle_view {
      display: flex;
    }
  }
}

.groupLayerName {
  height: min(30% - 50px, 300px);
  width: min(40% - 50px, 400px);

  .popup_heading {
    position: relative;

    button {
      position: absolute;
      right: 20px;
    }
  }

  .input {
    margin: 20px;
  }
}

.groupLayerName {
  .input {
    margin-bottom: 5px;

    input,
    select {
      height: 45px !important;
      border-radius: 8px;
      width: 100%;
      border: 1px solid;
    }
  }
}

.leaflet-control-layers-expanded {
  font-size: 10px;
  line-height: 0;
  font-family: "Montserrat";
}

.leaflet-popup-content {
  font-size: 22px; // Set the desired font size
  font-family: "Montserrat";
}

.latlongtooltip {
  font-size: 10px; // Set the desired font size
  padding: 10px; // Set the desired padding
  font-family: "Montserrat";
}

.uploadCustomLayer {
  .uploadBody {
    padding: 6px 12px;
    margin: 40px 0;

    background-color: #d3d3d354;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .multi_layer_upload {
      display: none;
    }

    .uploadSelectionBtns {
      display: flex;
      justify-content: space-between;

      button {
        width: 48%;
        height: 50px;
        margin: 20px 0;

        background-color: #009ec6;
        border: none;
        border-radius: 8px;
        color: white;

        &.active {
          background-color: #017d9d;
          border: 2px solid black;
        }
      }
    }

    .input {
      margin-bottom: 5px;

      input,
      select {
        height: 45px !important;
        border-radius: 8px;
        width: 100%;
        border: none;
      }
    }

    .toggle_custom {
      display: block;
    }
  }
}

.saveLoad_popup {
  // height: min(45% - 50px, 415px);
  // width: min(70% - 50px, 700px);
  position: fixed;
  inset: 100px;
  background-color: white;

  padding: 16px;
  overflow-y: scroll;

  @include popup_footer;
  @include popup_heading;

  .saveLoad_span {
    display: block;
    opacity: 0;
    position: relative;
    top: 0;

    border-radius: 8px;
    padding: 5px;
    width: 98%;

    text-align: center;
    background: rgba(0, 196, 84, 1);
    color: white;

    &.toggle_display {
      animation-name: inout;
      animation-duration: 4s;
    }

    @keyframes inout {
      0% {
        opacity: 0;
      }

      25% {
        opacity: 1;
      }

      50% {
        opacity: 1;
      }

      75% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }
  }

  .popup_heading .popup_close {
    position: absolute;
    right: 16px;
    top: 16px;
  }

  .popup_body {
    .input {
      margin-bottom: 5px;
      display: flex;
      flex-direction: column;

      input,
      select {
        height: 42pt !important;
        border-radius: 8px;
        padding: 10px;
        width: 300pt;
        border: 2px solid $vektaBlueDark;
      }

      .toggle_missingInfoShake {
        border: 2px solid red;
        animation: shakeError 0.2s ease-in-out;
      }

      @keyframes shakeError {
        0% {
          margin-left: 0rem;
        }

        25% {
          margin-left: 0.5rem;
        }

        75% {
          margin-left: -0.5rem;
        }

        100% {
          margin-left: 0rem;
        }
      }

      .toggle_missingInfo {
        border: 2px solid red;
      }
    }

    .saveCurrent_project {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .saveloadshare_action {
      // display: flex;
      // justify-content: center;
      // align-items: center;

      background: #dedede;

      width: 35px;
      height: 35px;

      border: none;
      border-radius: 8px;

      margin-right: 8px;
    }

    .saveLoad_btn {
      background: none;
      border: 2px solid $vektaBlueDark;
      border-radius: 8px;
      width: 23%;

      &:hover {
        background-color: $vektaBlueTrans !important;
      }
    }
  }

  .projectComments {
    background: white;
    padding: 18px;
    // position: fixed;
    // inset: 100px 100px 100px 60%;

    .commentBox_group {
      height: 70%;
      overflow-y: scroll;

      .commentBox {
        padding: 8px;
        margin: 16px;
        border-radius: 8px;
        background: $vektaBlueTrans;
      }
    }

    .newCommentBox {
      margin: 16px;

      .input {
        display: flex;
        flex-direction: column;

        textarea {
          height: 115px;
          margin-bottom: 8px;
          border-radius: 8px;
          border: 2px solid #009ec6;
          padding: 16px;
        }
      }

      footer button {
        border: 2px solid $vektaBlueDark;
        background-color: $vektaBlueDark;
        color: rgb(255, 255, 255);

        padding: 0 20px;
        margin-right: 10px;

        &:hover {
          color: black;
          background: none;
        }
      }
    }
  }
}

.shareWith_internalPopup {
  position: fixed;
  background: white;
  inset: 32%;
  padding: 16px;
  border-radius: 8px;

  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  align-items: center;

  .popup_heading {
    display: flex;
    justify-content: space-between;

    h3 {
      margin-top: 0;
    }

    .popup_close {
      position: absolute;
      right: 24px;

      padding: 10px;

      cursor: pointer;

      border: none;
      outline: none;

      &:hover {
        background-color: rgb(255, 0, 0);
        color: rgb(255, 255, 255);
      }
    }
  }

  .input {
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;

    input,
    select {
      height: 42pt !important;
      border-radius: 8px;
      padding: 10px;
      width: 300pt;
      border: 2px solid $vektaBlueDark;
    }

    .toggle_missingInfoShake {
      border: 2px solid red;
      animation: shakeError 0.2s ease-in-out;
    }

    @keyframes shakeError {
      0% {
        margin-left: 0rem;
      }

      25% {
        margin-left: 0.5rem;
      }

      75% {
        margin-left: -0.5rem;
      }

      100% {
        margin-left: 0rem;
      }
    }

    .toggle_missingInfo {
      border: 2px solid red;
    }
  }

  .sharableUsers {
    display: flex;
    flex-direction: column;
    align-items: center;

    .sharableUsers_list {
      flex-wrap: wrap;
      width: 55%;

      label {
        margin: 0 8px;
      }
    }
  }

  .popup_footer {
    .share_button {
      border: 2px solid $vektaBlueDark;
      background-color: $vektaBlueDark;
      color: rgb(255, 255, 255);

      padding: 0 20px;
      margin-right: 10px;

      &:hover {
        color: black;
        background: none;
      }
    }

    .cancel_button {
      border: 2px solid $vektaBlueDark;
      background: none;

      padding: 0 20px;
      margin-right: 10px;

      &:hover {
        background-color: $vektaBlueDark;
        color: rgb(255, 255, 255);
      }
    }
  }
}

.mapSnapshot_popup {
  width: calc(1500px / var(--scaleFactor));
  left: 12%;

  .popup_body {
    display: flex;
    position: relative;
    justify-content: space-between;

    height: 93%;

    .input {
      margin-bottom: 5px;

      input,
      select {
        height: 45px !important;
        width: 100%;

        border-radius: 8px;
        border: 1px solid;
      }
    }

    .snapshot_template {
      width: 70%;

      img {
        width: 100%;
      }
    }

    .snapshot_settings {
      height: 100%;
      overflow: scroll;

      h3 {
        text-transform: uppercase;
        writing-mode: vertical-rl;
        text-orientation: upright;
        font-size: 1.9rem;

        background: #9feed6;

        width: 50px;
        margin: 10px;
        margin-left: 0;

        padding-top: 10px;

        border-radius: 8px;

        display: flex;
        align-items: center;
      }

      .snapshot_sidePanel,
      .snapshot_mapEdits {
        display: flex;

        margin-right: 10px;
        margin-top: 10px;
        padding: 6px 12px;

        border-radius: 8px;
        background-color: #d3d3d354;
      }

      .input_checkbox {
        display: flex;
        justify-content: space-evenly;

        padding: 6px 12px;
        margin-top: 10px;
        background-color: white;
        border-radius: 8px 8px 0 0;

        input {
          width: 7%;
        }
      }

      .panel_onoff,
      .border_onoff {
        border-radius: 8px;
      }

      .mini_input,
      .snapshot_metaData,
      .snapshot_layerKey,
      .snapshot_subMap,
      .gridline_input,
      .snapshot_scaleBar,
      .snapshot_northArrow {
        display: none;

        padding: 6px 12px;
        border: 3px solid white;
        border-radius: 0 0 8px 8px;

        .snap_keyEdit,
        .snap_subMap {
          width: 100%;
          padding: 10px;

          background-color: #007592;
          color: white;

          border: none;
          border-radius: 8px;
        }
      }

      .toggle_view {
        display: block;
      }
    }

    .snapshot_insetMap {
      display: none;
      position: absolute;

      background: #fff;
      padding: 30px;

      margin: calc(50px / var(--scaleFactor)) calc(107px / var(--scaleFactor));
      width: 85%;
      z-index: 1006;

      .snapshot_insetMapHeading {
        display: flex;
        justify-content: space-between;

        .popup_close {
          float: right;

          padding: 10px;

          cursor: pointer;

          border: none;
          outline: none;

          &:hover {
            background-color: rgb(255, 0, 0);
            color: rgb(255, 255, 255);
          }
        }
      }

      footer {
        margin-top: 10px;

        footer button,
        a {
          float: right;

          height: 30px;

          border: 2px solid $vektaBlueDark;
          background: none;

          padding: 0 20px;
          margin-right: 10px;

          &:hover {
            background-color: $vektaBlueDark;
            color: rgb(255, 255, 255);
          }
        }

        a {
          display: flex;
          align-items: center;

          border-radius: 0px;
        }
      }
    }

    .popupOverlay {
      display: none;
      position: absolute;

      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;

      background: #999;
      opacity: 0.8;
      z-index: 1005;
    }
  }
}

.persistence_output {
  .popup_body {
    .probability_output {
      padding: 20px;
      margin: 20px 0;
      border-radius: 8px;

      .pre_optionsBtns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
        padding-bottom: 20px;
      }

      .per_outputGraphs {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding-top: 10px;

        .shiny-html-output {
          width: 1000px;
          overflow-x: scroll;
        }

        .plotly {
          margin-left: 25px;
        }
      }
    }
  }
}

.deleteLayer {
  // height: min(30% - 50px, 300px);
  // width: min(40% - 50px, 400px);

  height: min(40% - 50px, 200px);
  width: min(35% - 50px, 380px);

  .popup_heading {
    position: relative;

    button {
      position: absolute;
      right: 20px;
    }
  }

  .input {
    margin: 20px;
  }

  .popup_body {
    display: flex;
    justify-content: space-evenly;
    margin-top: 48px;

    button {
      float: right;

      height: 30px;

      border: 2px solid $vektaBlueDark;
      background: none;

      padding: 0 20px;
      margin-right: 10px;

      &:hover {
        background-color: $vektaBlueDark;
        color: rgb(255, 255, 255);
      }
    }
  }
}

.deleteWarning {
  // height: min(25% - 50px, 300px);
  // width: min(20% - 50px, 350px);

  height: min(40% - 50px, 300px);
  width: min(40% - 50px, 350px);

  .popup_heading {
    position: relative;

    svg {
      width: 25px;
      margin-right: 25px;
    }
  }

  .popup_body {
    display: flex;
    justify-content: space-evenly;
    margin-top: 25px;
  }

  footer button {
    float: right;

    height: 30px;

    border: 2px solid $vektaBlueDark;
    background: none;

    padding: 0 20px;
    margin-right: 10px;

    &:hover {
      background-color: $vektaBlueDark;
      color: rgb(255, 255, 255);
    }
  }
}

.saveOnClose_popup {
  height: min(20% - 50px, 250px);
  width: min(70% - 50px, 700px);
}

#help-popup {
  .saveLoad_span {
    display: block;
    opacity: 0;
    position: absolute;
    top: 16px;
    left: 80px;
    border-radius: 8px;
    padding: 5px;
    width: 90%;
    text-align: center;
    background: rgb(0, 196, 84);
    color: white;

    &.toggle_display {
      animation-name: inout;
      animation-duration: 4s;
    }

    @keyframes inout {
      0% {
        opacity: 0;
      }

      25% {
        opacity: 1;
      }

      50% {
        opacity: 1;
      }

      75% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }
  }
}

.privacyandcookies {
  height: min(55% - 50px, 360px);
  width: min(70% - 50px, 800px);
}

.siteLoad_popup {
  height: calc(300px / var(--scaleFactor));
}

.vakata-context {
  z-index: 1500;
}

.toggle_mainPane {
  display: block;
}

.vektaStore {
  .popup_heading {
    display: flex;

    h3 {
      margin-top: 0;
    }

    .vektaStoreProfile {
      width: 50px;

      svg {
        font-size: xx-large;
      }
    }

    .vektaStore_controlBtns {
      display: flex;
      justify-content: space-between;
      position: absolute;
      left: 310px;

      width: 200pt;
      height: 47px;

      button {
        height: 100%;
        width: 90pt;
      }
    }

    .popup_close {
      position: absolute;

      right: 40px;
      padding: 10px;

      cursor: pointer;

      border: none;
      outline: none;

      &:hover {
        background-color: rgb(255, 0, 0);
        color: rgb(255, 255, 255);
      }
    }
  }

  .popup_body {
    .toolsViewTab {
      display: none;

      .vektaStore_tool {
        background: #f5f5f5;

        padding: 16px;
        margin: 16px;
        width: 270pt;

        border-radius: 8px;

        .toolPricing {
          display: flex;
          align-items: flex-end;

          margin-top: 32px;

          p {
            margin-left: 8px;
          }
        }
      }

      &.toggle_group {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.popup_close_PandC {
  position: absolute;

  top: 10px;
  right: 10px;
  padding: 10px;

  cursor: pointer;

  border: none;
  outline: none;

  &:hover {
    background-color: rgb(255, 0, 0);
    color: rgb(255, 255, 255);
  }
}

.sidePanel_body {
  .input {
    display: flex;
    flex-direction: column;

    margin-bottom: 5px;

    input,
    select {
      height: 48pt !important;
      border-radius: 8px;
      padding: 10px;
      width: 340pt;
      border: 2px solid $vektaBlueDark;
    }

    .toggle_missingInfoShake {
      border: 2px solid red;
      animation: shakeError 0.2s ease-in-out;
    }

    @keyframes shakeError {
      0% {
        margin-left: 0rem;
      }

      25% {
        margin-left: 0.5rem;
      }

      75% {
        margin-left: -0.5rem;
      }

      100% {
        margin-left: 0rem;
      }
    }

    .toggle_missingInfo {
      border: 2px solid red;
    }
  }
}
