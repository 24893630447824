.new_popup,
.existing_popup {
  display: none;
  position: absolute;
  top: 40%;
  left: 46%;
  z-index: 1002;

  background: #fff;

  padding: calc(70px / var(--scaleFactor));

  width: calc(660px / var(--scaleFactor));
  height: calc(700px / var(--scaleFactor));
  overflow: scroll;

  margin-left: calc(-250px / var(--scaleFactor)); /*Half the value of width to center div*/
  margin-top: calc(-250px / var(--scaleFactor)); /*Half the value of height to center div*/

  transition: 0.5s ease-in-out;

  box-shadow: 2px 5px 16px 0px $vektaBlueDark, 5px 5px 15px 5px rgba(0,0,0,0);

  #popup_close {
    float: right;

    padding: 10px;

    cursor: pointer;

    border: none;
    outline: none;

    &:hover {
      background-color: rgb(255, 0, 0);
      color: rgb(255, 255, 255);
    }
  }

  .new_popup_content,
  .existing_popup_content {
    .popup_heading {
      display: flex;
      justify-content: space-between;
    }

    .error {
      color: rgb(255, 0, 0);
      font-weight: bold;
    }

    .project_name {
      margin-top: 10px;

      input {
        margin-top: 10px;
        padding: 5px;

        height: 50px;

        border-radius: 8px;
        border: 2px solid $vektaBlueDark;
      }
    }

    .setting_options {
      display: block;

      .setting_option_btns {
        display: flex;
        align-items: center;
        justify-content: space-around;

        margin: 25px 0px 35px 0px;

        button {
          width: 100px;
          height: 50px;

          cursor: pointer;

          border: none;
          outline: none;
        }

        .toggle_btn {
          background-color: rgb(110, 110, 110);
          color: rgb(255, 255, 255);
        }
      }
    }

    .action-button {
      float: right;

      width: 100px;
      height: 30px;

      border: 2px solid $vektaBlueDark;
      background: none;

      &:hover {
        background-color: $vektaBlueDark;
        color: rgb(255, 255, 255);
      }
    }

    .option_info {
      color: rgb(141, 141, 141);

      label {
        font-weight: bold;
      }

      p {
        margin-top: 5px;
      }
    }
  }

  .existing_popup_content {
    .form-group {
      width: 100%;
      margin-top: 15px;

      select {
        height: 250px;

        option {
          font-size: 15px;
        }
      }
    }
  }
}