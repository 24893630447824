.databaseCenter {
  display: none;
  width: 100%;
  position: relative;

  .databaseOptions_Btns {
    button {
      padding: 0;
      margin: 10px;

      position: relative;
      border: none;
      border-radius: 8px;

      .moving {
        width: calc(715px / var(--scaleFactor));
        height: calc(412px / var(--scaleFactor));
        border-radius: 8px;
      }

      .still {
        width: calc(715px / var(--scaleFactor));
        height: calc(412px / var(--scaleFactor));
        position: absolute;
        left: 0;
        filter: grayscale(1);
        border-radius: 8px;
      }

      h3 {
        position: absolute;
        top: 15px;
        left: 15px;

        color: white;
        font-family: vektaGroupB;
      }

      &:hover {
        box-shadow: 0px 0px 15px 0px #bdbdbd;

        .still {
          opacity: 0;
        }

        .moving {
          filter: saturate(0.5);
        }
      }
    }
  }

  .turbineDB_Content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;

    margin-top: 30px;
    height: 90%;
    color: black;

    hr {
      border-top: 3px solid rgb(159, 238, 214);
      width: 100%;
      margin: 0;
    }

    .databaseNav {
      position: absolute;
      top: -20px;
      left: 25px;
    }

    .turbineList,
    .turbineListP {
      width: 46%;
      height: 100%;

      padding: 10px;
      box-shadow: 0px 0px 15px 0px #bdbdbd;
      border-radius: 8px;

      .turbineList_heading,
      .turbineListP_heading {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .input {
          display: flex;

          input,
          select {
            width: calc(230px / var(--scaleFactor));
            height: 40px !important;

            border-radius: 8px;
            border: none;
          }
        }

        .basicSearch {
          width: 40%;

          display: flex;
          justify-content: space-between;

          .input {
            input {
              background-color: #80808014;
              border-radius: 8px 0px 0px 8px;
            }

            button {
              background-color: #80808014;
              border: none;
              border-radius: 0px 8px 8px 0px;
            }
          }
        }
      }

      .turb_Group {
        height: 92%;
        overflow: scroll;
      }

      .turbineList_search {
        display: none;
        justify-content: center;
        background-color: $vektaBlueDark;

        padding: 5px;
        border-radius: 8px;

        .filterRating {
          width: 30%;
        }

        .filterGroup {
          display: flex;
          flex-direction: column;

          .filterManufacturer {
            .form-group {
              width: 100%;
              margin: 0;
            }
          }

          .twoOptionFilters {
            display: flex;
            justify-content: space-between;
          }
        }
      }

      .toggle_filters {
        display: flex;
      }

      .turbinesFromDB,
      .turbinesFromP {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        overflow: scroll;

        .turb_card {
          position: relative;

          .turbineDB {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            position: relative;

            width: calc(240px / var(--scaleFactor));
            height: calc(200px / var(--scaleFactor));

            padding: calc(15px / var(--scaleFactor));
            margin: calc(10px / var(--scaleFactor));

            background: none;
            border: 3px solid $vektaBlueDark;
            border-radius: 8px;

            h4 {
              text-transform: uppercase;
            }

            .manRate {
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              width: 100%;
              margin-top: 10px;

              .man,
              .rate {
                display: flex;
                flex-direction: column;
                position: relative;

                label {
                  margin: 0;
                  position: absolute;
                  top: -15px;

                  color: #9feed6;
                  font-size: 15px;
                  text-transform: uppercase;
                  font-weight: 700;
                }

                h5 {
                  width: 100%;
                  padding: 10px;
                  margin: 0;

                  background-color: $vektaOtherBlue;
                  border-radius: 8px;

                  text-transform: capitalize;
                }
              }

              .man { 
                width: 100%;
              }

              .rate {
                margin-top: calc(20px / var(--scaleFactor));
                width: 35%;
              }
            }

            span {
              display: none;
              position: absolute;

              width: 120px;
              fill-opacity: 0.6;
            }
          }

          .turb_interrogate {
            position: absolute;
            display: none;
            left: 10px;
            top: calc(80px / var(--scaleFactor));

            width: 92%;

            align-items: center;
            justify-content: space-around;

            button {
              width: calc(60px / var(--scaleFactor));
              height: calc(60px / var(--scaleFactor));
            }
          }

          &:hover {
            .turbineDB {
              filter: blur(1px);
            }

            .turb_interrogate {
              display: flex;
            }
          }
        }
      }
    }

    .turbineListP {
      width: 45%;

      padding: 10px;
      box-shadow: 0px 0px 15px 0px #bdbdbd;
      border-radius: 8px;

      .turbineListP_heading {
        display: flex;
        justify-content: space-between;
      }

      .turbinesFromP {
        height: 92%;
      }
    }

    .turbineDB_Btn {
      display: flex;
      justify-content: center;
      align-items: center;

      border: none;
      border-radius: 8px;

      padding: 10px;

      &:hover {
        background: #c5c5c5;
      }
    }
  }
}

.customTurbine {
  width: 90%;
  left: 5%;

  hr {
    border-top: 3px solid rgb(159, 238, 214);
    width: 100%;
    margin-top: 0;
  }

  .popup_body {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .customTurb_mainInfo {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 50%;

    .inputGroup {
      display: flex;
      justify-content: space-between;

      .input {
        width: 49%;
      }
    }

    .groupedInputs {
      padding: 10px;
      width: 100%;

      display: flex;
      flex-direction: column;

      background: $vektaBlueDark;
      border-radius: 8px 8px 0px 0px;
      color: white;

      .input {
        input {
          text-transform: capitalize;
          color: black;
          width: 100%;
          height: 45px !important;

          border-radius: 8px;
          border: none;
        }
      }

      .turb_settings {
        display: flex;
        justify-content: space-around;

        margin-top: 20px;

        .input {
          display: flex;

          label {
            display: flex;
            flex-direction: column;

            width: 53px;

            img {
              width: 35px;
              height: 60px;

              filter: drop-shadow(2px 4px 6px black);
            }
          }

          span {
            margin-left: -50px;
            padding-left: 10px;
            width: 50px;
            height: 40px;

            color: $vektaBlue;

            h4 {
              width: 50px;
            }
          }

          input {
            width: 70%;
          }
        }

        label {
          margin-bottom: 0;
        }

        /* The switch - the box around the slider */
        .toggleSwitch {
          @include toggleSwitch;
        }
      }
    }

    .turbTransformers {
      padding: 10px;
      width: 100%;

      display: flex;
      flex-direction: column;

      border: 5px solid $vektaBlueDark;
      border-radius: 0px 0px 8px 8px;
      border-top: none;

      h4 {
        text-transform: uppercase;
      }

      .input {
        margin-bottom: 5px;

        input {
          width: 100%;
          height: 45px !important;
          //color: black;

          background: #b9b9b926;
          border-radius: 8px;
          border: none;
        }
      }

      .transformerClasses {
        display: flex;
        justify-content: space-around;

        .transformerStandard,
        .transformerEco {
          width: 49%;
        }
      }
    }

    .turbCost {
      padding: 10px;
      width: 100%;

      display: flex;
      flex-direction: column;

      border: 5px solid $vektaBlueDark;
      //color: white;

      h4 {
        text-transform: uppercase;
      }

      .input {
        margin-bottom: 5px;

        input {
          width: 100%;
          height: 45px !important;
          //color: white;

          background: #b9b9b926;
          border-radius: 8px;
          border: none;
        }
      }
    }
  }

  .customTurb_subInfo {
    display: flex;
    flex-direction: column;
    align-items: center;

    .wtg_powerCurve {
      position: relative;

      button {
        position: absolute;
        right: 25px;
        top: 60px;

        padding: 10px;

        border: none;
        border-radius: 8px;

        &:hover {
          background: #c5c5c5;
        }
      }

      .powercurveTable {
        display: none;

        position: absolute;
        top: 96px;
        right: 25px;

        height: 400px;
        padding: 10px;
        border-radius: 8px;
        background-color: buttonface;
      }

      .toggle_powercurveTable {
        display: block;
      }
    }

    .turbDimesions {
      position: relative;
      margin-right: 60px;

      .input {
        position: absolute;
        margin-bottom: 5px;

        border-radius: 8px;
        background-color: #007592;
        padding: 10px;

        label {
          color: white;
        }

        input,
        select {
          height: 45px !important;

          border-radius: 8px;
          border: none;
          width: 100%;
        }
      }

      .hubHeight {
        right: -170px;
        bottom: 30px;
        width: 37%;
      }

      .diameter {
        left: 50px;
        top: 144px;
        width: 34%;
      }

      .sweptArea {
        bottom: 19px;
        left: -11px;
        width: 43%;
      }

      .powerDensity {
        right: -170px;
        width: 36%;
      }
    }
  }
}

.toggle_mainPane {
  display: block;
}
