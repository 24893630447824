.cn-wrapper {
  font-size: 1em;
  width: 26em;
  height: 26em;
  overflow: hidden;
  position: fixed;
  z-index: 10;
  //   bottom: 16em;
  left: 50%;
  border-radius: 50%;
  margin-left: -13em;
  // transform: scale(0.1);
  opacity: 0;
  border: 1px solid lightgray;
  background-color: white;
  // transform: translate(0, -50%);
  transition: all 0.3s ease-in-out;

  &.opened-nav {
    border-radius: 50%;
    // transform: scale(1);
    opacity: 1;
    margin-left: -15.5em;
  }

  .first-controls {
    li {
      border: 1px solid lightgray;
      position: absolute;
      font-size: 1.5em;
      z-index: inherit;
      width: 10em;
      height: 10em;
      transform-origin: 100% 100%;
      overflow: hidden;
      left: 50%;
      // top: 50%;
      margin-top: -1.3em;
      margin-left: -10em;
      transition: border 0.3s ease;

      a {
        display: block;
        font-size: 1.18em;
        height: 14.5em;
        width: 14.5em;
        position: absolute;
        bottom: -7.25em;
        right: -7.25em;
        border-radius: 50%;
        text-decoration: none;
        color: rgba(0, 0, 0, 0.54);
        padding-top: 1.8em;
        text-align: center;
        transform: skew(-60deg) rotate(-70deg) scale(1);
        transition: background-color 0.2s ease, transform 0.2s ease;
        padding-right: 12px;

        svg {
          font-size: 1em;
          // opacity: 0.7;
          color: rgba(0, 0, 0, 0.54);
        }

        &:hover {
          background-color: #f5f5f5 !important;
          // transform: scale(1.1);
        }

        &:active,
        :focus {
          background-color: #e0e0e0;
        }
      }

      &:first-child {
        transform: rotate(-90deg) skew(60deg);
        // transform: skew(60deg);
        a svg {
          transform: rotate(160deg);
        }
      }
      &:nth-child(2) {
        transform: rotate(-60deg) skew(60deg);
        // transform: skew(60deg);
        a svg {
          transform: rotate(130deg);
        }
      }
      &:nth-child(3) {
        transform: rotate(-30deg) skew(60deg);
        // transform: skew(60deg);
        a svg {
          transform: rotate(100deg);
        }
      }
      &:nth-child(4) {
        transform: rotate(0deg) skew(60deg);
        // transform: skew(60deg);
        a svg {
          transform: rotate(70deg);
        }
      }
      &:nth-child(5) {
        transform: rotate(30deg) skew(60deg);
        // transform: skew(60deg);
        a svg {
          transform: rotate(35deg);
        }
      }
      &:nth-child(6) {
        transform: rotate(60deg) skew(60deg);
        // transform: skew(60deg);
        // a svg {
        //   transform: rotate(0deg);
        // }
      }
      // &:nth-child(6) {
      //   transform: rotate(180deg) skew(60deg);
      // }
    }
  }
}
