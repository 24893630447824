
.mentions__highlighter {
  padding: 8px;
  border: 2px inset transparent;
}


.mentions__input {
  background-color: white;
  padding: 8px;
  border: '1px solid #007bff';
  z-index: 1; /* Set z-index lower than other elements */
  border-radius: 8px;
  outline: none;

  /* font-family: "Montserrat"; */
}

.mentions__suggestions__list{
  background-color: #f2f2f2f2;
  font-size: 10pt;
  margin-left: 20px;
  z-index: 1000; /* Set z-index lower than other elements */
  border-radius: 8px;
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color: #90cedd;
}

/* .mentions__mention {
  position: relative;
  z-index: 1;
  color: #007bff;
  pointer-events: none;
  left: 100px;
  background-color: white;
} */