.sidebarButtons {
  width: 60px;
  min-width: 60px;
  height: 100vh;

  background-color: $vektaBlue;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .widgetBtns,
  .settingsBtns {
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      //margin: calc(10px / var(--scaleFactor)) 0;
      margin: 10px 0;
      @include widgetButtons;
      //padding: calc(5px / var(--scaleFactor));
      padding: 5px;
      fill: white;

      //height: calc(50px / var(--scaleFactor));
      //width: calc(50px / var(--scaleFactor));
      height: 4rem;
      width: 4rem;

      display: flex;
      align-items: center;
      justify-content: center;
      outline: unset;

      svg,
      img,
      i {
        //width: calc(35px / var(--scaleFactor));
        //height: calc(35px / var(--scaleFactor));

        //font-size: calc(30px / var(--scaleFactor));

        width: 3rem;
        height: 3rem;
        font-size: 30px;
      }

      img {
        filter: invert(1);
      }

      .profilePicture img {
        filter: unset;
        border-radius: 50%;
      }

      &.active {
        // background-color: rgba(159, 238, 214, 0.774);
        border-left: 3px solid white;
      }
    }
  }

  .settingsBtns {
    button {
      //margin: calc(5px / var(--scaleFactor)) 0;
      margin: 5px 0;
    }

    .vektaGroup {
      span {
        font-size: 11px;
        font-weight: 500;
        color: white;
        text-align: center;
        display: flex;
      }

      a {
        width: 100%;

        button {
          width: 100%;
          margin: 0;
          border-radius: 0;
          //height: calc(55px / var(--scaleFactor));
          height: 35px;

          img {
            //width: calc(50px / var(--scaleFactor));
            //height: calc(38px / var(--scaleFactor));
            filter: invert(0);

            width: 35px;
            height: 25px;
          }
        }
      }
    }
  }
}
