.vektaStore {
  font-family: "Montserrat" !important;

  .vektaStore_tool {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background: #f5f5f5;
    box-shadow: 6px 6px 6px #00000052;

    padding: 16px;

    width: 350px;
    height: 250px;

    border-radius: 8px;

    .toolPricing {
      display: flex;
      align-items: center;
      gap: 16px;

      margin-top: 32px;

      p {
        margin-left: 8px;
      }

      .productInstall {
        border-radius: 50px;
        width: 85%;
        background-color: $vektaBlue;
        border: none;
        color: white;

        padding: 16px;

        // &.productKey {
        //   color: black;
        //   background: none;
        //   border: 2px solid $vektaBlue;
        // }

        &:hover {
          scale: 1.05;
        }
      }
    }

    .ownedInfo {
      font-weight: 600;
    }
  }

  &.toggle_group {
    display: flex;
    flex-direction: column;
  }

  .toolDisplay {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    height: 100%;
    overflow-y: scroll;
    padding: 8px;
  }
}

.userProfile {
  .sidePanel_heading {
    display: flex;
    justify-content: center;

    h3 {
      margin-top: 0;
    }

    .vektaStoreProfile {
      width: 50px;

      svg {
        font-size: xx-large;
      }
    }

    .popup_close {
      position: absolute;

      right: 40px;
      padding: 10px;

      cursor: pointer;

      border: none;
      outline: none;

      &:hover {
        background-color: rgb(255, 0, 0);
        color: rgb(255, 255, 255);
      }
    }
  }

  .sidePanel_body {
    .yourInfo_heading {
      display: flex;
      justify-content: space-between;
    }
  }
}

.installTool {
  @include popup_heading;

  .purchaseSelect {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    width: 75%;
    padding: 5px;
    height: 6rem;
    margin-bottom: 16px;

    background-color: $vektaBlueDark;
    color: white;

    border: none;
    border-radius: 8px;

    font-size: 1.2rem;
    font-weight: 600;

    &:hover {
      background-color: $vektaBlueDark;
      box-shadow: inset 7px 8px 8px 0px #005b72;
    }
  }
}

.productKey_entry {
  .popup_body {
    margin-top: 24px;
  }

  .p-float-label input.p-filled ~ label {
    top: -1rem !important;
    color: black;
  }

  .p-inputtext:enabled:focus {
    // border: none;
    // outline: none;
    box-shadow: none;
  }

  .p-float-label input:focus ~ label,
  .p-float-label .p-inputwrapper-filled ~ label,
  .p-float-label .p-inputwrapper-focus ~ label {
    font-size: 16px;
    color: black;
    top: -1.5rem;
  }
}
