.mapOutput_popup {
  height: 95%;

  .popup_heading {
    display: flex;
    justify-content: space-between;

    h3 {
      margin-top: 0;
    }

    .popup_close {
      float: right;

      padding: 10px;

      cursor: pointer;

      border: none;
      outline: none;

      &:hover {
        background-color: rgb(255, 0, 0);
        color: rgb(255, 255, 255);
      }
    }
  }

  .downloadMap_button {
    position: absolute;
    right: 20px;
    bottom: 20px;

    padding: 8px;

    cursor: pointer;

    outline: none;
  }

  .mapContainer {
    .mainMap {
      .northArrow {
        width: fit-content;
        position: absolute;
        z-index: 401;

        &.topRight {
          top: 16px;
          right: 16px;
        }

        &.topLeft {
          top: 16px;
          left: 16px;
        }

        &.bottomRight {
          bottom: 16px;
          right: 16px;
        }

        &.bottomLeft {
          bottom: 16px;
          left: 16px;
        }
      }
    }

    .sideContent {
      .miniMap {
        width: 100%;
        height: 23%;
        border: 1px solid black;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .legendControl {
        height: 45%;
        border: 1px solid;

        .legend_layoutTree {
          .jstree-clicked {
            background: $vektaBlueDark !important;
          }

          .jstree-hovered {
            background: $vektaBlue !important;
          }

          .jstree-rename-input {
            color: black;
          }

          .jstree-default a {
            white-space: normal !important;
            height: auto;
          }

          .jstree-anchor {
            height: auto !important;
          }

          .jstree-default li > ins {
            vertical-align: top;
          }

          .jstree-leaf {
            height: auto;
          }

          .jstree-leaf a {
            height: auto !important;
            white-space: initial;
            max-width: 90%;
          }

          .jstree-disabled > .jstree-icon {
            opacity: 1;
            filter: none;
          }
        }
      }

      .metaData {
        border: 1px solid;
        padding: 8px;

        .metaData_options {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          p {
            width: 50%;
            font-size: smaller;
          }
        }
      }

      .metaData_extra {
        width: 100%;
        height: 10%;
        border: 1px solid;

        display: flex;
        justify-content: space-evenly;
        align-items: center;

        .scaleBar {
          width: 35%;
        }

        .logo {
          width: 25%;

          img {
            width: 100%;
          }
        }
      }
    }
  }

  .settingsContainer {
    .input {
      margin-bottom: 5px;
      display: flex;
      flex-direction: column;

      input,
      select {
        height: 42pt !important;
        border-radius: 8px;
        padding: 10px;
        width: 160pt;
        border: 2px solid $vektaBlueDark;
      }

      .toggle_missingInfoShake {
        border: 2px solid red;
        animation: shakeError 0.2s ease-in-out;
      }

      @keyframes shakeError {
        0% {
          margin-left: 0rem;
        }

        25% {
          margin-left: 0.5rem;
        }

        75% {
          margin-left: -0.5rem;
        }

        100% {
          margin-left: 0rem;
        }
      }

      .toggle_missingInfo {
        border: 2px solid red;
      }
    }

    .coords_options {
      display: flex;
      justify-content: space-evenly;
    }

    .metaData_settingInputs {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }

    .settings_section {
      width: 100%;
      // border: 2px solid $vektaBlue;
      background: #f6f6f6;

      display: flex;
      flex-direction: column;
      gap: 10px;

      margin-bottom: 10px;
      padding: 10px;
      border-radius: 8px;

      .mapOption {
        display: flex;
        align-items: center;
        justify-content: space-between;

        height: 45px;
        background: $vektaBlue;
        padding: 8px;
        border-radius: 6px;

        h4 {
          margin: 0;
          color: white;
        }

        input[type="checkbox"] {
          appearance: none;
          outline: none;
          background-color: #fff;
          margin: 0;

          font: inherit;
          color: $vektaBlue;
          width: 2.5rem;
          height: 2.5rem;
          border: 0.15em solid $vektaBlue;
          border-radius: 4px;
          transform: translateY(-0.075em);

          display: grid;
          place-content: center;

          &::before {
            content: "";
            width: 0.7em;
            height: 0.7em;

            transform: scale(0);

            transform-origin: bottom left;
            clip-path: polygon(
              14% 44%,
              0 65%,
              50% 100%,
              100% 16%,
              80% 0%,
              43% 62%
            );

            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em $vektaBlue;
            border-radius: 3px;
          }

          &:checked::before {
            transform: scale(1);
          }
        }

        label {
          margin: 0 8px 0 0;
          color: white;
        }
      }
    }

    .grid_settings .grid_options .grid_input {
      display: flex;
      justify-content: space-between;

      .input {
        width: 80%;
      }

      input {
        width: 15%;
        background-color: #e9e9e9;
        border-radius: 8px;
        border: none;
        padding: 8px;
      }
    }
  }
}
